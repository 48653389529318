import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, HashRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons";
import LoginPage from "views/pages/LoginPage";
import LandingPage from "views/pages/LandingPage";
import ProfilePage from "views/pages/ProfilePage";
import HomePage from "views/pages/HomePage"
import DataPlatformPage from 'views/pages/DataPlatformPage'
import ContactUsPage from 'views/pages/ContactUsPage'
import DynamicPage from 'views/pages/DynamicPage'
import EducationPage from 'views/pages/EducationPage'
import PaperAndPeriodical from 'views/pages/PaperAndPeriodical'
import MeetingAndActivityPage from 'views/pages/MeetingAndActivity'
import AboutPage from "views/pages/About";
import DynamicMorePage from "views/pages/DynamicMorePage";
import CaseListPage from "views/pages/CaseListPage";
import SearchListPage from "views/pages/SearchList";
import ActivityDetailPage from "views/pages/ActivityDetail";
import ArticleDetailPage from "views/pages/ArticleDetail";
import VideoDetailPage from "views/pages/VideoDetail";
import Partner from "views/pages/Partner"
import MeetingTheme from 'views/pages/meetingTheme'

import ResizeManager from "components/common/ResizeManager.js"
const width = 1920;
const height = 1080;
const mode = window.resizeMode || ResizeManager.MODE_DEBUG;

ReactDOM.render(
  <HashRouter>
    {/* <ResizeManager fullWidth={width} fullHeight={height} mode={mode} /> */}
    <Switch>
      <Switch>
        <Route path="/examples" render={(props) => <Index {...props} />} />
        <Route path="/index" render={(props) => <HomePage {...props} />} />
        <Route
          path="/nucleo-icons"
          render={(props) => <NucleoIcons {...props} />}
        />
        <Route
          path="/landing-page"
          render={(props) => <LandingPage {...props} />}
        />
        <Route
          path="/profile-page"
          render={(props) => <ProfilePage {...props} />}
        />
        <Route
          path="/login"
          render={(props) => <LoginPage {...props} />}
        />
        <Route
          path="/platform"
          render={(props) => <DataPlatformPage {...props} />}
        />
        <Route
          path="/contactus"
          render={(props) => <ContactUsPage {...props} />}
        />
        <Route
          path="/dynamic"
          render={(props) => <DynamicPage {...props} />}
        />
        <Route
          path="/education"
          render={(props) => <EducationPage {...props} />}
        />
        <Route
          path="/paperperiodical"
          render={(props) => <PaperAndPeriodical {...props} />}
        />
        <Route
          path="/meetingactivity"
          render={(props) => <MeetingAndActivityPage {...props} />}
        />
        <Route
          path="/about"
          render={(props) => <AboutPage {...props} />}
        />
        <Route
          path="/dynamicmore"
          render={(props) => <DynamicMorePage {...props} />}
        />
        <Route
          path="/caselist"
          render={(props) => <CaseListPage {...props} />}
        />
        <Route
          path="/searchlist"
          render={(props) => <SearchListPage {...props} />}
        />
        <Route
          path="/activitydetail"
          render={(props) => <ActivityDetailPage {...props} />}
        />
        <Route
          path="/articledetail"
          render={(props) => <ArticleDetailPage {...props} />}
        />
        <Route
          path="/videodetail"
          render={(props) => <VideoDetailPage {...props} />}
        />
        <Route
          path="/meetingTheme"
          render={(props) => <MeetingTheme {...props} />}
        />
        <Route
          path="/partner"
          render={(props) => <Partner {...props} />}
        />
        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
