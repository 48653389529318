/** 
 * 时间格式化
*/
export const formatDateTime = (dt, type) => {
  let str = ""; //存储时间的字符串
  //获取年
  let year = dt.getFullYear();
  //获取月
  let month = dt.getMonth() + 1;
  //获取日
  let day = dt.getDate();
  //获取小时
  let hour = dt.getHours();
  //获取分钟
  let min = dt.getMinutes();
  //获取秒
  let sec = dt.getSeconds();
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  hour = hour < 10 ? "0" + hour : hour;
  min = min < 10 ? "0" + min : min;
  sec = sec < 10 ? "0" + sec : sec;
  if (!type) {
    return str = year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
  }
  if (type === 'yyyy-MM-dd HH:mm:ss') {
    return str = year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
  }
  if (type === 'yyyy/MM/dd HH:mm:ss') {
    return str = year + "/" + month + "/" + day + " " + hour + ":" + min + ":" + sec;
  }
  if (type === 'yyyy-MM-dd HH:mm') {
    return str = year + "-" + month + "-" + day + " " + hour + ":" + min;
  }
  if (type === 'yyyy/MM/dd HH:mm') {
    return str = year + "/" + month + "/" + day + " " + hour + ":" + min;
  }
  if (type === 'yyyy-MM-dd') {
    return str = year + "-" + month + "-" + day;
  }
  if (type === 'yyyy/MM/dd') {
    return str = year + "/" + month + "/" + day;
  }
  if (type === 'HH:mm:ss') {
    return str = hour + ":" + min + ":" + sec;
  }
  if (type === 'HH:mm') {
    return str = hour + ":" + min;
  }
  return str = year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
}

export const objToArray = (array) => {
  if(array instanceof Array) return array;
  
  var arr = []
  for (var i in array) {
    arr.push(array[i]); 
  }
  return arr;
}

export const getQueryVariable = (path, variable) =>  {
  var query = path.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
    if(pair[0] === variable){return pair[1];}
  }
  return(false);
}

export function deepClone(obj){
  let objClone = Array.isArray(obj)?[]:{};
  if(obj && typeof obj==="object"){
    for(let key in obj){
      if(obj.hasOwnProperty(key)){
        //判断ojb子元素是否为对象，如果是，递归复制
        if(obj[key]&&typeof obj[key] ==="object"){
          objClone[key] = deepClone(obj[key]);
        }else{
          //如果不是，简单复制
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
}

export function countDown (ev) {
  var nowDate, nowTimes, lastTimes, lastSeconds, lastD, lastH, lastM, lastS;
  // 1. 定义将来时间
  var preDate = new Date(ev);
  var preTimes = preDate.getTime();   // 获取目标日期距离1970年1月1日毫秒数
  let now = new Date().getTime();
  if(preDate < now) return '已结束'
  // 3. 获取当前时间
  nowDate = new Date();
  // 4. 获取距离1970年1月1日毫秒数
  nowTimes = nowDate.getTime();
  // 5. 获取剩余毫秒数
  lastTimes = preTimes - nowTimes;
  // 6. 毫秒转换
  // ① 毫秒转换为秒
  lastSeconds = parseInt(lastTimes / 1000);
  // ② 通过秒转天、时、分、秒
  lastD = size(parseInt(lastSeconds / 3600 / 24));
  lastH = size(parseInt(lastSeconds / 3600 % 24));
  lastM = size(parseInt(lastSeconds / 60 % 60));
  lastS = size(parseInt(lastSeconds % 60));
  // 7. 将结果放入div
  return lastD +'天' + lastH + '小时' + lastM + '分钟' + lastS + '秒！'

  function size(number){
      return number < 10 ? '0' + number : number ;
  }
}