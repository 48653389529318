import React, { useState } from "react";
import { useHistory } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import PageHeader from "components/PageHeader";
import Pagination from 'rc-pagination';
import './index.scss'
import '../MeetingAndActivity/pagination.less'

import { getThemeMeeting } from '../../../api/api.js'
import { getQueryVariable } from '../../../utils/utils.js'
import searchIcon from 'assets/img/paper/search.png'
import bgImg from 'assets/images/showcase-bg.jpg'
import clock from 'assets/images/clock.png'


function MeetingAndActivityPage() {
  const [headerSolgan, setHeaderSolgan] = useState({title: '学习园地', solgan: '分享最新研究成果 共建优质科研平台', bgImg, position: '25%'})
  const [lastestList, setLastestList] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [themeName, setThemeName] = useState('0')
  const [current, setCurrent] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [total, setTotal] = useState(0)
  const history = useHistory()
  React.useEffect(() => {
    let title = getQueryVariable(history.location.search, 'title')
    setThemeName(decodeURIComponent(title))
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    function setScale() {
      let scale = window.innerWidth/1920
      let height = 1920/window.innerWidth * 100 + 'vh'
      document.querySelector('#root').style.zoom = scale //`scale(${scale})`
      document.querySelector('#root .auto-height').style.minHeight = `calc(${height} - 510px)`
    }
    setScale()
    window.onresize = function() {
      setScale()
    }
    getRecentMeetingList()
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  //最近更新
  const getRecentMeetingList = async (pageNum) => {
    let themeId = getQueryVariable(history.location.search, 'id')
    let params = {
      pageSize: pageSize,
      pageNum: pageNum?pageNum:current,
      themeId,
      title: searchValue
    }
    let res = await getThemeMeeting(params)
    if(res.code == 0) {
      setLastestList(res.rows)
      setTotal(res.total)
    }
  }

  //搜索框输入
  const handleSetValue = (e) => {
    setSearchValue(e.target.value)
  }

  const handleKeyUp = (e) => {
    if(e.keyCode == 13) {
      handleSearch()
    }
  }
  
  // 跳转搜索
  const handleSearch = () => {
    console.log(searchValue)
    setCurrent(1)
    getRecentMeetingList(1)
  }

  // 翻页
  const onChange = (page) => {
    setCurrent(page)
    getRecentMeetingList(page)
  }

  // 跳转详情
  const handleDetail = (item) => {
    let url = ''
    if(item.activityKind == 1) {
      url = `/videoDetail?id=${item.id}&type=3`
    }else {
      url = `/activitydetail?id=${item.id}&type=3`
    }
    history.push(url)
  }

  return (
    <div className="meeting-activity-wapper">
      <Navbar />
      <PageHeader headerSolgan={headerSolgan}></PageHeader>
      <div className="meeting-activity-main auto-height">
        <Container>
          <div className="meeting-activity-lastest">
            <div className="lastest-header">
              <div className="lastest-title">{themeName}</div>
              <div className="search">
                <Input placeholder="请输入查询关键字" onChange={(e) => handleSetValue(e)} onKeyUp={(e) => handleKeyUp(e)} />
                <img src={searchIcon} alt="" onClick={() => handleSearch()} />
              </div>
            </div>
            <div className="lastest-content">
              {
                lastestList.map((lastest, index) => (
                  <div md="4" style={{margin: `${(index+2)%3==0?'0 30px 40px':'0 0 40px'}`}} key={`lastest${index}`} onClick={() => handleDetail(lastest)}>
                    <div style={{width: '100%', position: 'absolute', top: 0,}}>
                      <div className="lastest-img">
                        <img src={lastest.thumbnailUrl} alt="" />
                        {
                          lastest.isMemberExclusive == 1?<span className="tips">会员</span>:null
                        }
                      </div>
                    </div>
                    <div className="lastest-item">
                      <div className="publish-name">
                        <span>{lastest.title}</span>
                      </div>
                      <div className="publish-title">{lastest.description}</div>
                      <div className="publish-time">
                        <img src={clock} alt="" />
                        <span>{lastest.publishTime}</span>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
            <Pagination
              onChange={onChange}
              current={current}
              pageSize={pageSize}
              total={total}
            />
            {
              /**
              <div className="more">
                <div className="line-left"></div>
                <Button color="3d8d9f">查看更多</Button>
                <div className="line-right"></div>
              </div>
               */
            }
          </div>
        </Container>
      </div>
      <TransparentFooter />
    </div>
  );
}

export default MeetingAndActivityPage;
