/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import logoV from 'assets/images/vascbase-m.png'

function TransparentFooter() {
  return (
    <footer className="footer">
      <Container>
        <nav>
          <ul>
            <li>
              <a href="/#/about" style={{fontSize: '20px'}}>
                关于我们
              </a>
            </li>
            <li>
              <a href="" style={{fontSize: '20px'}}>
                用户须知
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright" id="copyright" style={{fontSize: '20px'}}>
          <span>版权所有© 2022 </span>
          <span>沪ICP备2022009386号-2</span>
        </div>
      </Container>
      <img src={logoV} alt="" />
    </footer>
  );
}

export default TransparentFooter;
