import React, { useState } from "react";
import { useLocation } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Container,
  Input,
  Row,
  Col
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import Notification from 'rc-notification';
import './index.scss'
import './notification.less'
import { setLeavingMessage } from '../../../api/api.js'
import { getQueryVariable } from 'utils/utils.js'

import erweima from 'assets/images/us/erweima.png'
import phone from 'assets/images/us/tel.png'
import location from 'assets/images/us/location.png'
import ewm from 'assets/images/us/erweima.jpg'

let notificationInstance = null
Notification.newInstance({}, n => {
  notificationInstance = n;
});

function ContactUsPage() {
  const [name, setName] = useState('')
  const [tel, setTel] = useState('')
  const [email, setEmail] = useState('')
  const [content, setContent] = useState('')
  const [height, setHeight] = useState(0)
  const { search } = useLocation()
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    setHeiht()
    window.onresize = function() {
      setHeiht()
      setScales()
    }
    function setScales() {
      let scale = window.innerWidth/1920
      document.querySelector('#root').style.zoom = scale //`scale(${scale})`
    }
    setScales()
    let name = getQueryVariable(search, 'callInfo')
    let tel = getQueryVariable(search, 'phone')
    let email = getQueryVariable(search, 'mailbox')
    if(name) {
      setName(name)
    }
    if(tel) {
      setTel(tel)
    }
    if(email) {
      setEmail(email)
    }
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const setHeiht = () => {
    let scale = 1920/window.innerWidth * 100 + 'vh'
    setHeight(scale)
  }

  // 留言
  const setLeavingMessageData = async () => {
    let params = {
      callInfo: name,
      phone: tel,
      mailbox: email,
      content: content
    }
    let res = await setLeavingMessage(params)
    if(res.code == 0) {
      notificationInstance.notice({
        content: <span>留言成功</span>,
      })
    }else {
      notificationInstance.notice({
        content: <span>{res.msg}</span>,
      })
    }
  }

  // 设置留言
  const handleSetValue = (e, type) => {
    if(type == 'name') {
      setName(e.target.value)
    }else if(type == 'tel') {
      setTel(e.target.value)
    }else if(type == 'email') {
      setEmail(e.target.value)
    }else if(type == 'content') {
      setContent(e.target.value)
    }
  }

  return (
    <div className="contact-us-wapper" style={{minHeight: height, maxHeight: 'initial', paddingBottom: '85px'}}>
      <Navbar />
      <div className="page-header clear-filter" filter-color="blue">
        <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/header.jpg").default + ")",
            }}
          ></div>
          <div className="content" style={{}}>
            <Container>
              <div className="contact-us-logo">
                
              </div>
            </Container>
          </div>
      </div>
      <div className="contact-us-main">
        <Container>
          <Row className="contact-us-content">
            <Col className="content-form" md="5">
              <div className="content-form-title">
                <h3>欢迎留言</h3>
                <p>您可以在任何时间向我们发送您想要了解或者交流的内容，我们的专职服务人员会在第一时间与您取得联系。</p>
              </div>
              <div className="content-form-body">
                <div className="form-item">
                  <Input type="text" defaultValue={name} onChange={(e) => handleSetValue(e, 'name')} placeholder="您的昵称" />
                </div>
                <div className="form-item">
                  <Input type="email" defaultValue={email} onChange={(e) => handleSetValue(e, 'email')} placeholder="电子邮箱" />
                </div>
                <div className="form-item">
                  <Input type="tel" defaultValue={tel} onChange={(e) => handleSetValue(e, 'tel')} placeholder="联系电话" />
                </div>
                <div className="form-item">
                  <Input type="textarea" onChange={(e) => handleSetValue(e, 'content')} placeholder="" />
                </div>
                <div className="form-item">
                  <Button color="3d8d9f" onClick={() => setLeavingMessageData()}>提交</Button>
                </div>
              </div>
            </Col>
            <Col className="content-info-wapper" md="7">
              <Row>
                <Col className="content-info" md="6">
                
                </Col>
                <Col className="content-info" md="6">
                  <div className="content-info-item">
                    <div className="info-icon">
                      <img src={location} alt="" />
                    </div>
                    <div className="info-detail">
                      <div className="info-detail-title">
                        <span>通讯地址</span>
                      </div>
                      <div className="info-detail-content">
                        <span>北京市西城区长福路201号 长富大厦A座8021室</span>
                      </div>
                    </div>
                  </div>
                  <div className="content-info-item">
                    <div className="info-icon">
                      <img src={phone} alt="" />
                    </div>
                    <div className="info-detail">
                      <div className="info-detail-title">
                        <span>联系电话</span>
                      </div>
                      <div className="info-detail-content">
                        <span>固定电话 010 - 8748328</span>
                      </div>
                    </div>
                  </div>
                  <div className="content-info-item">
                    <div className="info-icon">
                      <img src={erweima} alt="" />
                    </div>
                    <div className="info-detail">
                      <div className="info-detail-title">
                        <span>微信公众号 (联盟在线）</span>
                      </div>
                      <div className="info-detail-content">
                        <img src={ewm} alt="" />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{position: 'absolute', width: '100%', bottom: 0}}>
        <TransparentFooter />
      </div>
    </div>
  );
}

export default ContactUsPage;
