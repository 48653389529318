import React, {useState} from "react";
import { useHistory } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Container,
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
// import Video from 'assets/video/home.mp4'
import './index.scss'

function HomePage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [height, setHeight] = useState(0)
  const history = useHistory()
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    window.onresize = function() {
      setHeiht()
      setScale()
    }
    document.body.scrollTop = 0;
    function setScale() {
      let scale = window.innerWidth/1920
      document.querySelector('#root').style.zoom = scale //`scale(${scale})`
    }
    setScale()
    setHeiht()
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const setHeiht = () => {
    let scale = window.innerHeight / (window.innerWidth/1920) + 'px'
    setHeight(scale)
  }
  return (
    <>
      <Navbar color="rgb(0,77,143)" />
      <div className="home-header" filter-color="blue" style={{height: height}}> {/** */}
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/images/background-f.jpg").default + ")",
          }}
        >
          {/* <video autoplay="autoplay" muted style={{minWidth: '100%', minHeight: '100%'}}>
            <source src={Video} />
          </video> */}
        </div>
        <div className="content" style={{height: `calc(${height} - 130px)`,paddingTop: '20%'}}>
          <div className="home-wapper">
            <div className="home-main">
              <div className="home-slogan" style={{height: '95px'}}>
                {/**<span>联盟SLOGAN</span> */}
              </div>
              <div className="home-desc" style={{height: '1px'}}>
                {/**<span>以创新驱动为向导，以推动血脉诊疗技术为目标，通过多科学多领域的技术整合，进行血脉诊疗技术及规划化创新研究，提高血脉诊疗专业领域整体医疗技术平，提升净化医疗服务能力。</span> */}
              </div>
              
            </div>
            <div className="home-action">
                <Button
                  className="zm"
                  color="primary"
                >
                  联盟成员招募
                </Button>
                <Button
                  className="lj"
                  color="primary"
                  outline
                  onClick={() => history.push('/about')}
                >
                  了解更多
                </Button>
              </div>
          </div>
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default HomePage;
