import React, { useState } from "react";
import { useHistory } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import PageHeader from "components/PageHeader";
import './index.scss'
import searchIcon from 'assets/img/paper/search.png'
import { getRecommendPaperPeriodical, getListPaperPeriodical } from '../../../api/api.js'
import bgImg from 'assets/images/wz.jpg'
import man from 'assets/images/man.png'
import clock from 'assets/images/clock.png'

function SearchListPage() {
  const [headerSolgan, setHeaderSolgan] = useState({title: '论文/期刊', solgan: '分享最新研究成果 共建优质科研平台', bgImg, position: '25%'})
  const [lastestList, setLastestList] = useState([])
  const [recommendList, setRecommendList] = useState([])
  const [current, setCurrent] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchValue, setSearchValue] = useState('')
  const [more, setMore] = useState(true)
  const history = useHistory()
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    function setScale() {
      let scale = window.innerWidth/1920
      let height = 1920/window.innerWidth * 100 + 'vh'
      document.querySelector('#root').style.zoom = scale //`scale(${scale})`
      document.querySelector('#root .auto-height').style.minHeight = `calc(${height} - 510px)`
    }
    setScale()
    window.onresize = function() {
      setScale()
    }
    getRecommendPaperPeriodicalList()
    getPaperPeriodicalList(1)
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  // 推荐列表
  const getRecommendPaperPeriodicalList = async () => {
    let res = await getRecommendPaperPeriodical()
    if(res.code == 0) {
      setRecommendList(res.rows)
    }
  }
  
  // 最近更新列表
  const getPaperPeriodicalList = async (current) => {
    let params =  {
      pageSize: pageSize,
      pageNum: current
    }
    let res = await getListPaperPeriodical(params)
    if(res.code == 0) {
      let list = lastestList
      list = list.concat(res.rows)
      setLastestList(list)
      if(res.rows.length < 10) {
        setMore(false)
      }
    }
  }

  //搜索框输入
  const handleSetValue = (e) => {
    setSearchValue(e.target.value)
  }

  const handleKeyUp = (e) => {
    if(e.keyCode == 13) {
      handleSearch()
    }
  }
  
  // 跳转搜索
  const handleSearch = () => {
    console.log(searchValue)
    if(!searchValue) return
    history.push(`/searchlist?search=${searchValue}`)
  }

  // 活动详情
  const handleDetail = (item) => {
    history.push(`/articledetail?id=${item.id}&type=2`)
  }

  //更多
  const handleMore = () => {
    let currentPage = current + 1
    setCurrent(currentPage)
    getPaperPeriodicalList(currentPage)
  }
  
  return (
    <div className="paper-periodical-wapper">
      <Navbar />
      <PageHeader headerSolgan={headerSolgan}></PageHeader>
      <div className="paper-periodical-main auto-height">
        <Container>
          <div className="paper-periodical-lastest">
            <div className="lastest-header">
              <div className="lastest-title">推荐阅读</div>
              <div className="search">
                <Input placeholder="请输入查询关键字" onChange={(e) => handleSetValue(e)} onKeyUp={(e) => handleKeyUp(e)} />
                <img src={searchIcon} alt="" onClick={() => handleSearch()} />
              </div>
            </div>
            <ul className="recommend-list">
              {
                recommendList.map((recommend, index) => (
                  <li key={index} onClick={() => handleDetail(recommend)}>
                    <div className="recommend-img">
                      <img src={recommend.thumbnailUrl} alt="" />
                    </div>
                    <div className="recommend-main">
                      <div className="recommend-title">{recommend.title}</div>
                      <div className="recommend-desc">{recommend.description}</div>
                      <div className="recommend-from-date">
                        <div className="recommend-from">
                          <img src={man} alt="" />
                          <span>{recommend.authorInfo} 单位:{recommend.affiliatedUnit}</span>
                        </div>
                        <div className="recommend-date">
                          <img src={clock} alt="" />
                          <span>{recommend.publishTime && recommend.publishTime.split(' ')[0]}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
          <div className="paper-periodical-lastest">
            <div className="lastest-header">
              <div className="lastest-title">最近更新</div>
            </div>
            <ul className="lastest-list">
              {
                lastestList.map((lastest, index) => (
                  <li key={index} onClick={() => handleDetail(lastest)}>
                    <div className="recommend-main">
                      <div className="recommend-title">{lastest.title}</div>
                      <div className="recommend-from-date">
                        <div className="recommend-from">
                          {
                            lastest.label && lastest.label.split(',').map((label, index) => (
                              <span className="lastest-tag" key={index}>{label}</span>
                            ))
                          }
                        </div>
                        <div className="recommend-date">
                          <img src={clock} alt="" />
                          <span>{lastest.publishTime && lastest.publishTime.split(' ')[0]}</span>
                        </div>
                      </div>
                      <div className="recommend-desc">{lastest.description}</div>
                      <div className="recommend-from-date">
                        <div className="recommend-from">
                          <img src={man} alt="" />
                          <span>{lastest.authorInfo} 单位:{lastest.affiliatedUnit}</span>
                        </div>
                        <div className="recommend-date">
                          <span>临床试验进展</span>
                          <span className="publish-status">[{lastest.publishStatus == 1?'已发表':'未发表'}]</span>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              }
            </ul>
            {
              more?
              <div className="more">
                <div className="line-left"></div>
                <Button color="3d8d9f" onClick={() => handleMore()}>查看更多</Button>
                <div className="line-right"></div>
              </div>:null
            }
          </div>
        </Container>
      </div>
      <TransparentFooter />
    </div>
  );
}

export default SearchListPage;
