import React, { useState } from "react";
import { useHistory } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import PageHeader from "components/PageHeader";
import './index.scss'
import searchIcon from 'assets/img/paper/search-fff.png'

import { getDynamicData } from '../../../api/api.js'
import clock from 'assets/images/clock.png'
import bgImg from 'assets/images/showcase-bg.jpg'

function DynamicMorePage() {
  const [headerSolgan, setHeaderSolgan] = useState({title: '联盟动态', solgan: '分享最新研究成果 共建优质科研平台', bgImg})
  const [lastestList, setLastestList] = useState([])
  const [searchBar, setSearchBar] = useState([{name: '全部', id: 0},{name: '教育案例', id: 1},{name: '论文期刊', id: 2},{name: '会议活动', id: 3}])
  const [current, setCurrent] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [currentLabel, setCurrentLabel] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [search, setSearch] = useState('')
  const history = useHistory()
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    function setScale() {
      let scale = window.innerWidth/1920
      let height = 1920/window.innerWidth * 100 + 'vh'
      document.querySelector('#root').style.zoom = scale //`scale(${scale})`
      document.querySelector('#root .auto-height').style.minHeight = `calc(${height} - 510px)`
    }
    setScale()
    window.onresize = function() {
      setScale()
    }
    getDynamicList(1,0)
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const handleChangeLabel = (label) => {
    setCurrentLabel(label.id)
    setCurrent(1)
    getDynamicList(1,label.id)
  }

  //获取联盟动态
  const getDynamicList = async (current, contentType) => {
    let params = {
      pageSize,
      pageNum: current,
      title: search
    }
    if(contentType != 0) {
      params.contentType = contentType
    }
    let res = await getDynamicData(params)
    if(res.code == 0) {
      let list = lastestList
      if(current == 1) {
        list = []
      }
      list = list.concat(res.rows)
      setLastestList(list)
      if(res.rows.length < 10) {
        setHasMore(false)
      }
    }
  }

  // 更多
  const handleMore = () => {
    let currentPage = current + 1
    setCurrent(currentPage)
    getDynamicList(currentPage, currentLabel)
  }

  // 搜索
  const handleSearch = () => {
    setCurrent(1)
    getDynamicList(1, currentLabel)
  }

  //跳转详情
  const handleToMore = (item) => {
    console.log(item.contentType)
    let url = ''
    if(item.contentType == 1) {
      if(item.caseKind == 1) {
        url = `/videoDetail?id=${item.contentId?item.contentId:item.id}&type=1`
      }else {
        url = `/articledetail?id=${item.contentId}&type=1`
      }
    }else if(item.contentType == 2) {
      url = `/articledetail?id=${item.contentId}&type=2`
    }else if(item.contentType == 3) {
      if(item.activeKind == 1) {
        url = `/videoDetail?id=${item.contentId?item.contentId:item.id}&type=3`
      }else {
        url = `/activitydetail?id=${item.contentId}&type=3`
      }
    }
    history.push(url)
  }

  return (
    <div className="dynamic-more-wapper">
      <Navbar />
      <PageHeader headerSolgan={headerSolgan}></PageHeader>
      <div className="dynamic-more-main auto-height">
        <Container>
          <div className="dynamic-more-lastest">
            <div className="dynamic-more-search-bar">
              <ul className="search-bar-labels">
                {
                  searchBar.map(search => (
                    <li key={search.id} onClick={() => handleChangeLabel(search)} className={search.id == currentLabel?'active':''}>{search.name}</li>
                  ))
                }
              </ul>
              <div className="search-bar">
                <Input type="text" placeholder="请输入查询关键字" onChange={(e) => setSearch(e.target.value)} />
                <div className="search-btn" onClick={() => handleSearch()}>
                  <img src={searchIcon} alt="" />
                </div>
              </div>
            </div>
            <ul className="lastest-list">
              {
                lastestList.map((lastest, index) => (
                  <li key={index} onClick={() => handleToMore(lastest)}>
                    <div className="recommend-main">
                      <div className="recommend-title">{lastest.title}</div>
                      <div className="recommend-from-date">
                        <div className="recommend-from">
                          {
                            lastest.label && lastest.label.split(',').map((label, index) => (
                              <span className="lastest-tag" key={index}>{label}</span>
                            ))
                          }
                        </div>
                        <div className="recommend-date">
                          <img src={clock} alt="" />
                          <span>{lastest.publishTime && lastest.publishTime.split(' ')[0]}</span>
                        </div>
                      </div>
                      <div className="recommend-desc">{lastest.description}</div>
                    </div>
                  </li>
                ))
              }
            </ul>
            {
              hasMore?
              <div className="more">
                <div className="line-left"></div>
                <Button color="3d8d9f" onClick={() => handleMore()}>查看更多</Button>
                <div className="line-right"></div>
              </div>:null
            }
          </div>
        </Container>
      </div>
      <TransparentFooter />
    </div>
  );
}

export default DynamicMorePage;
