import request from '../utils/request.js';
import { stringify } from 'qs'


// 联盟动态列表
export function getDynamicData(data) {
  let params = {
    url: '/api/listDynamic',
    method: 'post',
    data: stringify(data),
  }
  return request(params)
}

// 联盟动态轮播图
export function getDynamicSwper(data) {
  let params = {
    url: '/api/listSliderShow',
    method: 'post',
    data,
  }
  return request(params)
}

// 教育案例置顶列表
export function getTopTeachCase(data) {
  let params = {
    url: '/api/topTeachCase',
    method: 'post',
    data,
  }
  return request(params)
}

// 教育案例列表
export function getListTeachCase(param) {
  let params = {
    url: '/api/listTeachCase',
    method: 'post',
    data: stringify(param),
  }
  return request(params)
}

// 订阅
export function setSubscribe(param) {
  let params = {
    url: '/api/subscribe',
    method: 'post',
    data: stringify(param),
  }
  return request(params)
}

// 论文/期刊推荐列表
export function getRecommendPaperPeriodical(data) {
  let params = {
    url: '/api/recommendPaperPeriodical',
    method: 'post',
    data,
  }
  return request(params)
}

// 论文/期刊列表
export function getListPaperPeriodical(param) {
  let params = {
    url: '/api/listPaperPeriodical',
    method: 'post',
    data: stringify(param),
  }
  return request(params)
}

// 置顶会议
export function getTopMeetingActivity(param) {
  let params = {
    url: '/api/topMeetingActivity',
    method: 'post',
    data: stringify(param),
  }
  return request(params)
}

// 会议最近更新
export function getRecentMeeting(param) {
  let params = {
    url: '/api/recentMeeting',
    method: 'post',
    data: stringify(param),
  }
  return request(params)
}

// 会议最近更新
export function getThemeMeeting(param) {
  let params = {
    url: '/api/listMeetingActivityByTheme',
    method: 'post',
    data: stringify(param),
  }
  return request(params)
}

// 会议更多动态
export function getListMeetingActivity(param) {
  let params = {
    url: '/api/listMeetingActivity',
    method: 'post',
    data: stringify(param),
  }
  return request(params)
}

// 留言
export function setLeavingMessage(param) {
  let params = {
    url: '/api/leavingMessage',
    method: 'post',
    data: stringify(param),
  }
  return request(params)
}

// 文章详情
export function getDetailPaperPeriodical(param) {
  let params = {
    url: '/api/detail',
    method: 'post',
    data: stringify(param),
  }
  return request(params)
}

// 活动详情
export function getDetailMeetingActivity(param) {
  let params = {
    url: '/api/detail',
    method: 'post',
    data: stringify(param),
  }
  return request(params)
}

// 视频详情
export function getDetailTeachCase(param) {
  let params = {
    url: '/api/detail',
    method: 'post',
    data: stringify(param),
  }
  return request(params)
}

// 登录
export function login(param) {
  let params = {
    url: '/api/login',
    method: 'post',
    data: stringify(param),
  }
  return request(params)
}

// 数据平台列表
export function getDataPlatform(param) {
  let params = {
    url: '/authApi/userSysList',
    method: 'post',
    data: stringify(param),
  }
  return request(params)
}

// 分享统计
export function getShareStat(param) {
  let params = {
    url: '/api/shareStat',
    method: 'post',
    data: stringify(param),
  }
  return request(params)
}

// 分享
export function setShare(param) {
  let params = {
    url: '/api/share',
    method: 'post',
    data: stringify(param),
  }
  return request(params)
}

//第三方登录
// 数据平台列表
export function loginSys(param) {
  let params = {
    url: '/authApi/userSysLogin',
    method: 'post',
    data: stringify(param),
  }
  return request(params)
}