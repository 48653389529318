/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
// core components
import './index.scss'

function PageHeader(props) {
  let pageHeader = React.createRef();
  console.log(props)
  React.useEffect(() => {
    console.log(props.headerSolgan.bgImg)
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + props.headerSolgan.bgImg + ")",
            backgroundPositionY: props.headerSolgan.position?props.headerSolgan.position:''
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="content-center brand" style={{color: props.headerSolgan.color?props.headerSolgan.color:'#fff'}}>
            <h1 className="page-header-title">{props.headerSolgan.title}</h1>
            <h3 className="page-header-solgan">{props.headerSolgan.solgan}</h3>
          </div>
        </Container>
      </div>
    </>
  );
}

export default PageHeader;
