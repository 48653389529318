import React, { useState } from "react";
import { useHistory } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Container,
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import PageHeader from "components/PageHeader";
import './index.scss'
import bgImg from 'assets/images/wz.jpg'
import Shunzhan from 'assets/images/partner/shunzhanlogo.png'
import YXJL from 'assets/images/partner/yxjl.png'
import GuiChuang from 'assets/images/partner/guichuangyiliao.png'
import XRD from 'assets/images/partner/xianruida.jpg'
import Boshidun from 'assets/images/partner/boshidun.jpg'

function SearchListPage() {
  const [headerSolgan, setHeaderSolgan] = useState({title: '合作伙伴', solgan: '希望加入联盟，请联系我们', bgImg, position: '25%'})
  const [partnerList, setPartnerList] = useState([])
  const history = useHistory()
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    let partnerList = [
      {id: 0, name: '上海顺展医疗科技有限公司', url: 'http://www.shunzhansh.com', img: Shunzhan},
      {id: 1, name: '北京医学奖励基金会', url: 'http://www.yxjl.org/', img: YXJL},
      {id: 2, name: '归创医疗', url: 'https://cn.zyloxtb.com/', img: GuiChuang},
      {id: 3, name: '先瑞达', url: 'http://www.acotec.cn', img: XRD},
      {id: 3, name: '波士顿科学', url: 'http://www.bostonscientific.cn', img: Boshidun}
    ]
    setPartnerList(partnerList)
    function setScale() {
      let scale = window.innerWidth/1920
      let height = window.innerHeight / (window.innerWidth/1920) + 'px'
      document.querySelector('#root').style.zoom = scale //`scale(${scale})`
      document.querySelector('#root .auto-height').style.minHeight = `calc(${height} - 510px)`
    }
    setScale()
    window.onresize = function() {
      setScale()
    }
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  
  return (
    <div className="partner-wapper">
      <Navbar />
      <PageHeader headerSolgan={headerSolgan}></PageHeader>
      <div className="partner-main auto-height">
        <Container>
          <div className="partner-content">
            {
              partnerList.map((partner) => (
                <a href={partner.url} target="_black" key={partner.id}>
                  <div className="partner-item">
                    {/**<span>{partner.name}</span> */}
                    <div>
                      <img src={partner.img} alt="" />
                    </div>
                  </div>
                </a>
              ))
            }
          </div>
        </Container>
      </div>
      <TransparentFooter />
    </div>
  );
}

export default SearchListPage;
