
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";

import cookie from "js-cookie";

import logo from '../../assets/images/logo.png'
import logo1 from '../../assets/images/logo1.png'
import logoB from '../../assets/images/logo-black.png'
import logoV from 'assets/images/vascbase-m.png'
import './Navbar.scss'

function ExamplesNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [menuOpen, setMenuOpen] = useState(true)
  const [showMenuOpen, setShowMenuOpen] = useState(true)
  const [menuList, setMenuList] = useState([
    {id: 0, name: '首页', url: '/index'},
    {id: 1, name: '联盟动态', url: '/dynamic'},
    {id: 2, name: '会员专区', url: '/education'},
    {id: 3, name: '合作与支持', url: '/partner'},
    {id: 4, name: '学习园地', url: '/meetingactivity'},
    {id: 5, name: '数据平台', url: '/platform'}
  ])
  const [logoutShow, setLogoutShow] = useState(false)
  const history = useHistory()
  React.useEffect(() => {
    console.log(props.color)
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    const updateShowMenuOpenStatus = () => {
      // if(window.document.body.offsetWidth <= 991) {
      //   setShowMenuOpen(false)
      // }else {
      //   setShowMenuOpen(true)
      // }
    }
    updateShowMenuOpenStatus()
    window.addEventListener("scroll", updateNavbarColor);
    window.addEventListener("resize", updateShowMenuOpenStatus);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
      window.removeEventListener("resize", updateShowMenuOpenStatus);
    };
  });

  const menuToPage = (menu) => {
    if(history.location.pathname == menu.url) return
    history.push(menu.url)
  }

  const menuMouseEnter = (menu) => {
    if(menu.id == 5 && cookie.get('token')) {
      setLogoutShow(true)
    }
  }

  const menuMouseLeave = () => {
    setLogoutShow(false)
  }

  const logout = () => {
    cookie.remove('token')
    setLogoutShow(false)
    window.location.href = '/#/login'
  }


  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={`fixed-top ${navbarColor} navbar-wapper`} color="info" expand="lg">
        <div className="header-main">
          <div className="navbar-logo" onClick={() => window.location.href = '/#/'}>
            <span style={{color: props.color?props.color:'#fff'}}>
              <img src={props.color == '#333'?logoB:props.color == 'rgb(0,77,143)'?logo:logo1} alt=""/>
            </span>
            {/**<img src={logoV} alt="" /> */}
          </div>
          <div className="navbar-translate">
            <div></div>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end menu-collapse"
            isOpen={collapseOpen}
            navbar
          >
            <div className="navbar-menu-wapper" style={{height: showMenuOpen?'42px':'100%'}}>
              <div className={`${menuOpen||!showMenuOpen?'show':'hide'} navbar-menu-list`} style={{width: showMenuOpen?'100%':'100%', justifyContent: showMenuOpen?'flex-end':'center'}}>
                <Nav navbar>
                  {
                    menuList.map(menu => (
                      <NavItem key={menu.id}>
                        <span 
                          className="navbar-menu-item" 
                          onMouseEnter={() => menuMouseEnter(menu)}  
                          onMouseLeave={() => menuMouseLeave()}
                          style={{color: props.color?props.color:'#fff'}} 
                          onClick={() => menuToPage(menu)}
                          >
                            {menu.name}
                          </span>
                      </NavItem>
                    ))
                  }
                </Nav>
              </div>
            </div>
            {
              logoutShow?
              <div className="menu-logout" 
                onMouseEnter={() => menuMouseEnter({id: 5})}  
                onMouseLeave={() => menuMouseLeave()}>
                  <span className="logout-btn" onClick={() => logout()}>退出登录</span>
              </div>:null
            }
            {
              showMenuOpen?
              <button
                className="toggler-menu"
                onClick={() => {
                  setMenuOpen(!menuOpen);
                }}
                type="button"
              >
                <span className="navbar-toggler-bar top-bar" style={{background: props.color?props.color:'#fff'}}></span>
                <span className="navbar-toggler-bar middle-bar" style={{background: props.color?props.color:'#fff'}}></span>
                <span className="navbar-toggler-bar bottom-bar" style={{background: props.color?props.color:'#fff'}}></span>
              </button>:null
            }
          </Collapse>
          {/**<img src={logoV} alt="" /> */}
        </div>
      </Navbar>
    </>
  );
}

export default ExamplesNavbar;

