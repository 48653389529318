import React, { useState } from "react";
import { useHistory } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Container,
  Input,
  Row,
  Col
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import PageHeader from 'components/PageHeader/index.js'
import Notification from 'rc-notification';
import './index.scss'
import './notification.less'

import { setLeavingMessage } from '../../../api/api.js'
import MemberImg from 'assets/img/about/member.png'
import bgImg from 'assets/images/about.jpg'
import fengzibo from 'assets/images/about/fengzibo.jpg'
import guolianrui from 'assets/images/about/guolianrui.jpg'
import hechunshui from 'assets/images/about/hechunshui.jpg'
import liqiang from 'assets/images/about/liqiang.jpg'
import fangxin from 'assets/images/about/fangxin.jpg'
import yemeng from 'assets/images/about/yemeng.jpg'
import shiweihao from 'assets/images/about/shiweihao.jpg'
import shizhenyu from 'assets/images/about/shizhenyu.jpg'
import img9 from 'assets/images/about/9.png'
import img10 from 'assets/images/about/10.png'
import img11 from 'assets/images/about/11.png'
import wuzileng from 'assets/images/about/wuzileng.jpg'
import sanghongfei from 'assets/images/about/sanghongfei.jpg'

let notificationInstance = null
Notification.newInstance({}, n => {
  notificationInstance = n;
});

function AboutPage() {
  const [headerSolgan, setHeaderSolgan] = useState({title: '关于联盟', solgan: '分享最新研究成果 共建优质科研平台',bgImg, color: '#333'})
  const [researcherList, setResearcherList] = useState([
    {id: 1, avatar: fengzibo, name: '冯自波', desc: '医学硕士，副主任医师，硕士生导师，华中科技大学同济医学院附属梨园医院创面修复、血管外科二科主任，湖北省慢性创面及糖尿病足临床医学研究中心副主任。学会任职：国际血管联盟中国分部青年委员会常委，国际血管联盟中国分部下肢动脉委员会委员，中国医师协会糖尿病足分会委员，中国人体健康科技促进会血管外科学专业委员会委员，中国中西医结合学会周围血管疾病专业委员会委员，中国医疗保健国际交流促进会糖尿病足分会委员，湖北省医学会血管外科分会委员，湖北省医学会血管外科分会糖尿病足学组秘书，湖北省中医药学会周围血管病专业委员会委员。医疗：累计完成下肢血管腔内手术约2000台，擅长周围动脉闭塞血管成形术，动脉瘤腔内隔绝修复术、深静脉血栓清除术，下肢静脉曲张的微创手术等。科研：主持湖北省自然科学基金面上项目一项，华中科技大学自主创新基金一项，发表论文十余篇，其中SCI 3篇，参编《糖尿病足及下肢慢性创面》、《下肢血管外科学》、《中华皮肤软组织损伤康复学》等论著5本，获得湖北省科学技术进步三等奖一项。'},
    {id: 2, avatar: guolianrui, name: '郭连瑞', desc: '医学博士，主任医师、 副教授、 硕士研究生导师、首都医科大学宣武医院血管外科 副主任（主持工作）、首都医科大学血管外科学系副主任。担任国际血管联盟（IUA）中国分会秘书长、国际血管联盟中国分部下肢动脉疾病专家委员会主任委员、中国医促会糖尿病足病分会常委兼秘书长、《中国血管外科杂志（电子版）》编委、《中华细胞与干细胞杂志（电子版）》第三届编辑委员会委员、北京围手术期医学研究会血管外科专业委员会副主任委员、北京医师协会血管外科专科医师分会常务理事、北京医师学会第五届理事会理事、北京医师协会第二届血管与腔内血管外科专家委员会委员、北京医学会血栓与止血学分会第二届委员会常务委员、第一届泛血管医学专家委员会委员、北京医学会血管外科分会第四届委员会头臂动脉学组副组长、中华医学会医学工程学分会第八届委员会外科医学工程与转化学组委员、中国康复医学会糖尿病预防与康复专业委员会第一委员、中国微循环学会周围血管疾病专业委员会第二届委员会副秘书长、中国医师协会介入医师分会第二届委员会大血管介入专业委员、中国医药教育协会血管外科专业委员会常务委员、中华医学会组织修复与再生分会血管再生学组委员、中华预防医学会组织感染与损伤预防与控制专业委员会副主任委员。'},
    {id: 3, avatar: sanghongfei, name: '桑宏飞', desc: '主任医师，副教授，博士，博士生导师。中华医学会老年病分会血管外科专业委员会委员，中华血管外科杂志通信编委，中国医师协会腔内血管学专业委员会主动脉瘤专家委员会委员，中国微循环学会周围血管疾病专业委员会委员，江苏省医学会外科学分会血管外科分会委员兼学术秘书，江苏省医学会介入医学分会血管介入学组委员。以第一作者发表医学文章十余篇，其中 SCI 2 篇，中华核心 6 篇。主持市级课题 2 项，参与国家级、省级及市级课题研究 7 项。获省 级、市级医学新技术引进奖各 1 项。从事外科临床近20年，多年来关注发展的亚专科方向为动脉系统疾病的开放和腔内治疗。在江苏省内率先开展 SilverHawk 和 TurboHawk 定向斑块旋切技术联 合药物球囊治疗下肢动脉硬化闭塞症的初发和复发病例，这项新技术的开展，进一步提高了该疾病的预后，至今已累积治疗 100 余例。对于复杂主动脉夹层及解 剖条件不良的腹主动脉瘤等疾病，率领团队在省内较早开展“杂交手术”、“平行支架技术”等新技术。多年来一直关注动脉血管疾病的临床和基础研究，发表了多篇高质量论文，相关技术分别获得苏州市医学新技术奖和江苏省医学新技术引进奖等奖。'},
    {id: 4, avatar: liqiang, name: '李强', desc: '副主任医师，青岛市海慈医疗集团血管外科中心副主任兼二科主任，从事中西医结合血管外科工作近20余年，熟练掌握血管外科疾病的诊治，曾前往国内多家医院进修血管外科，2015年曾在世界最大下肢动脉腔内手术中心德国莱比锡大学附属医院学习，尤其擅长颈动脉内膜剥脱术、胸腹主动脉瘤以及夹层腔内以及手术治疗、下肢慢性缺血性疾病及糖尿病足的腔内以及手术治疗,个人下肢动脉腔内手术例数位居山东省前列，每年受邀前往省内二十余家医院指导下肢腔内手术。'}
  ])
  const [researcherInfoList, setResearcherInfoList] = useState([])
  const [name, setName] = useState('')
  const [tel, setTel] = useState('')
  const [email, setEmail] = useState('')
  const history = useHistory()
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    let researchInfoLists = [
      {id: 1,avatar: fangxin, name: '方欣团队', title: '杭州市第一人民医院', desc: '杭州市第一人民医院血管外科成立于2010年，主要从事血管外科常见病及危重疑难疾病的诊治。现有血管外科专科医师9名，核定床位21张。硬件设施齐全，配备DSA大型数字剪影血管造影机（美国GE），Trivex 静脉刨吸装置（美国Smith Nephew），半导体激光治疗仪（意大利Eufoton），肢体多普勒血流探测仪（日本Hadeco），ClosureFast射频消融机，彩色多普勒超声（德国SIEMENS）等国际先进设备。 年均手术量逾1000台，尤其擅长下肢动脉硬化闭塞症以及动脉瘤的腔内个体化治疗以及静脉曲张的微创治疗。 与纽约长老会医院、台湾荣民总医院以及北京、上海、广州等国内外血管外科中心建立良好的合作关系，对于血管外科急、危重症及疑难病例的诊治有丰富的经验。 目前开展各类血管外科开放及腔内手术，治疗范围包括： 【静脉系统疾病】：下肢静脉曲张，深静脉瓣膜功能不全，深静脉血栓形成，静脉血栓后遗症，胡桃夹综合征（左肾静脉压迫综合征），髂静脉压迫综合征，肺动脉栓塞。 【外周动脉疾病】：下肢动脉硬化闭塞症、血栓闭塞性脉管炎，糖尿病足，颈、椎动脉、锁骨下动脉狭窄，动脉栓塞，内脏动脉狭窄及动脉瘤（肾动脉、肠系膜上动脉、脾动脉等）。 【主动脉疾病】：各种胸、腹主动脉瘤，主动脉夹层等；肿瘤相关性血管疾病、动静脉通路维护、血管瘤的微创及手术治疗及各类血管损伤等。'},
      {id: 2,avatar: yemeng, name: '叶猛', title: '上海交通大学医学院附属仁济医院 主任', desc: '主任医师，医学博士。就职于上海交通大学医学院附属仁济医院血管外科。担任中国医疗保健国际交流促进会糖尿病足分会青年学组副组长；中国医疗保健国际交流促进会血管外科分会血透通路学组常务委员；中国微循环学会周围血管疾病专业委员会中青年委员会副主任委员；中国医师协会腔内血管学专业委员会下肢动脉疾病委员会委员；上海市医学会血管外科专科分会委员；《中华血管外科杂志》，《中国血管外科杂志（电子版）》编委，《临床误诊误治》杂志通讯编委。'},
      {id: 3,avatar: shiweihao, name: '史伟浩', title: '复旦大学附属华山医院 副主任', desc: '博士，复旦大学附属华山医院血管外科副主任医师，中国医师协会血管外科医师委员会青年委员会委员，上海市医学会血管外科专科分会委员兼秘书，上海市医师协会血管外科医师分会委员兼秘书，国家卫健委脑卒中防治工程委员会中青年专家委员会委员，海峡两岸医药卫生交流协会血管外科分会静脉学组委员'},
      {id: 4,avatar: shizhenyu, name: '史振宇', title: '复旦大学附属中山医院 主任', desc: '医学博士，复旦大学附属中山医院血管外科静脉亚专科主任，主任医师，博士研究生导师。现任中国医师协会血管外科专业委员会静脉学组副组长，中国医师协会腔内血管学专业委员会中青年委员，上海VTE防治联盟秘书，国际血管联盟(IUA)委员，IUA中国区静脉血栓栓塞症副主委、中青年副主委，美国血管外科学会国际会员，中国医疗促进会血管外科专业委员会血栓与抗凝专家委员会常委，海峡两岸医疗交流协会静脉学组委员。中华实验外科杂志通讯编委、血管与腔内血管外科杂志常务编委。主要的研究方向是主动脉扩张性疾病、下肢动脉硬化症的腔内治疗以及深静脉血栓的临床与基础研究。负责承担国家自然科学基金和上海市自然基金4项。近年来发表学术论文近80篇，其中SCI收录35篇。'},
      {id: 5,avatar: wuzileng, name: '吴子衡', title: '浙大一院 副主任', desc: '博士，副主任医师，硕士生导师，浙大一院血管外科科室副主任。主持国家自然科学基金项目2项，担任国自然科学基金、省自然科学基金评审专家。第一作者或通讯作者发表SCI论文20余篇，担任多个国际血管外科专业期刊审稿人。任中国医师协会血管外科医师分会下肢动脉学组委员，中国医师协会腔内血管学下肢动脉疾病专家委员会委员，中国老年医学会周围血管疾病管理分会常务委员，中国研究型医院血管医学青委会委员，中国医促会血管外科分会颈动脉学组委员，IUA肿瘤相关血管重建专家委员会委员，浙江省医学会血管外科学分会委员，浙江省医学会血栓与止血分会委员，浙江省医师协会外科分会血管外科学组委员，浙江省中西医结合学会周围血管疾病分会常委兼青委副主委。'},
      {id: 6,avatar: hechunshui, name: '何春水', title: '成都中医药大学附属医院 主任', desc: '博士，教授，硕士研究生导师，成都中医药大学附属医院血管外科主任，四川省学术和技术带头人后备人选，四川省卫生厅学术和技术带头人，四川省中医药管理局学术和技术带头人，现任四川省医学会血管瘤与脉管畸形委员会主任委员，四川省中医药学会周围血管疾病专业委员会主任委员。擅长胸主动脉瘤（夹层），腹主动脉瘤，颈动脉狭窄，四肢动脉狭窄的手术、介入治疗及血管健康管理。2000年博士毕业即开始从事血管外科工作，多次到国外交流学习最先进的血管疾病诊治技术。2002年-2004年在美国克利夫兰临床医疗中心进行外科博士后工作；2010年12月-2011年3月在德国波恩St Marien医院进修血管外科。对血管疾病具有丰富的临床诊治经验，分别于31岁破格晋升副教授，34岁破格晋升教授。2006年创建成都中医药大学附属医院血管外科，现已成为四川省重点学科。在省内率先开展血管外科临床新技术十余项，长期承担四川、云南及贵州等数十家医院手术会诊，均取得良好疗效。热爱中医，积极继承和研究血管疾病的中医辨证论治规律，形成了系统、有效的中西医结合预防和治疗血管疾病方案。现已在国内外著名杂志发表论著70余篇，SCI收录10篇，获四川省科技进步二等奖1项，国家及部省级课题资助10余项。'}
    ]
    setResearcherInfoList(researchInfoLists)
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  // 留言
  const setLeavingMessageData = async () => {
    let params = {
      callInfo: name,
      phone: tel,
      mailbox: email,
    }
    history.push(`/contactus?callInfo=${name}&phone=${tel}&mailbox=${email}`)
    return false
    let res = await setLeavingMessage(params)
    if(res.code == 0) {
      notificationInstance.notice({
        content: <span>留言成功</span>,
      })
    }
  }

  // 设置留言
  const handleSetValue = (e, type) => {
    if(type == 'name') {
      setName(e.target.value)
    }else if(type == 'tel') {
      setTel(e.target.value)
    }else if(type == 'email') {
      setEmail(e.target.value)
    }
  }

  return (
    <div className="about-wapper">
      <Navbar color={'#333'} />
      <PageHeader headerSolgan={headerSolgan}></PageHeader>
      <div className="about-main">
        <Container>
          <div className="about-desc">
            <p>以创新驱动为向导，以推动血脉诊疗技术为目标，通过多科学多领域的技术整合，进行血脉诊疗技术及规划化创新研究，提高血脉诊疗专业领域整体医疗技术平，提升净化医疗服务能力</p>
          </div>
          <div className="member">
            <h3 className="about-title">联盟成员</h3>
            <div className="member-logo">
              <img src={MemberImg} alt="" />
            </div>
          </div>
          <div className="researcher">
            <h3 className="about-title">研究者</h3>
            <p>联盟主要成员包含多位业内知名专家学者，联盟主要成员包含多位业内知名专家学者，联盟主要成员包含多位业内知名专家学者，联盟主要成员包含多位业内知名专家学者，联盟主要成员包含多位业内知名专家学者，联盟主要成员包含多位业内知名专家学者，联盟主要成员包含多位业内知名专家学者，</p>
            <div className="researcher-desc">
              <Row>
                {
                  researcherList.map(researcher => (
                    <Col md="3" key={researcher.id}>
                      <div className="desc-item">
                        <img src={researcher.avatar} alt="" />
                        <span>{researcher.name}</span>
                        <span title={researcher.desc}>{researcher.desc}</span>
                      </div>
                    </Col>
                  ))
                }
              </Row>
            </div>
            <div className="researcher-info">
              <Row>
                {
                  researcherInfoList.map(researcherInfo => (
                    <Col md="6" key={researcherInfo.id} style={{marginBottom: '50px'}}>
                      <div className="researcher-info-item">
                        <div className="researcher-info-avatar">
                          <img src={researcherInfo.avatar} alt="" />
                        </div>
                        <div className="researcher-info-info">
                          <span className="researcher-info-name">{researcherInfo.name}</span>
                          <span className="researcher-info-title">{researcherInfo.title}</span>
                          <span className="researcher-info-desc" title={researcherInfo.desc}>{researcherInfo.desc}</span>
                        </div>
                      </div>
                    </Col>
                  ))
                }
              </Row>
            </div>
            {
              /**
               * <div className="honor">
              <h3 className="about-title">联盟荣誉</h3>
              <p>荣誉介绍荣誉介绍荣誉介绍荣誉介绍荣誉介绍荣誉介绍荣誉介绍荣誉介绍荣誉介绍荣誉介绍荣誉介绍，荣誉介绍荣誉介绍荣誉介绍，荣誉介绍荣誉介绍，荣誉介绍荣誉介绍，荣誉介绍荣誉介绍</p>
              <div className="honor-img-list">
                <Row>
                  <Col md="4">
                    <div className="honor-img">
                      <img src={img9} alt="" />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="honor-img">
                      <img src={img10} alt="" />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="honor-img">
                      <img src={img11} alt="" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
               */
            }
            <div className="contact">
              <h3 className="about-title">联系我们</h3>
              <p>“专业、专注、合作、诚信、创新”是我们的核心价值理念，我们始终贯彻和坚持这个理念，希望能够汇聚更多英才，一起感受这份激情，一同见证这件神圣使命！</p>
              <div className="contact-form">
                <Row style={{margin: '60px 0 40px'}}>
                  <Col md="4">
                    <div className="form-item">
                      <Input type="text" onChange={(e) => handleSetValue(e, 'name')} placeholder="姓名" />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="form-item">
                    <Input type="tel" onChange={(e) => handleSetValue(e, 'tel')} placeholder="联系电话" />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="form-item">
                    <Input type="email" onChange={(e) => handleSetValue(e, 'email')} placeholder="电子邮箱" />
                    </div>
                  </Col>
                </Row>
                <div className="form-button">
                  <Button color="3d8d9f" onClick={() => setLeavingMessageData()}>留言</Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {
        /**
         * 
        
         */
      }
      <TransparentFooter />
    </div>
  );
}

export default AboutPage;
