export function qzoneShare(args) {
  let shareUrl = 'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey'
  shareUrl += `?url=${args.url}`
  if (args.title) {
    shareUrl += `&title=${args.title}`
  }
  if (args.description) {
    shareUrl += `&desc=${args.description}`
  }
  if (args.description) {
    shareUrl += `$summary=${args.description}`
  }
  if (args.site) {
    shareUrl += `$site=${args.site}`
  }
  return shareUrl
}

export function qqShare(args) {
  //?url=https://wiki.connect.qq.com/%e5%88%86%e4%ba%ab%e5%8a%9f%e8%83%bd%e5%ad%98%e5%82%a8%e6%9d%83%e9%99%90%e9%80%82%e9%85%8d?source=shareqq&desc=请参考QQ互联文档库中的《分享功能存储权限适配》，希望对你有帮助。&title=分享功能存储权限适配-QQ互联文档库&summary=&pics=&flash=&site=QQ互联文档库&callback=http://cgi.connect.qq.com/report/report_vm?tag=0&log=分享功能存储权限适配_11133_0&t=1668417869442
  let shareUrl = 'https://connect.qq.com/widget/shareqq/index.html'
  shareUrl += `?url=${args.url}&source=shareqq`
  if (args.title) {
    shareUrl += `&title=${args.title}`
  }
  // if (args.description) {
  //   shareUrl += `&desc=${args.description}`
  // }
  // if (args.source) {
  //   shareUrl += `$source=${args.source}`
  // }
  // if (args.image) {
  //   shareUrl += `$pics=${args.image}`
  // }
  return shareUrl
}

export function tencentShare(args) {
  let shareUrl = 'http://share.v.t.qq.com/index.php'
  shareUrl += `?c=share&a=index&url=${args.url}`
  if (args.title) {
    shareUrl += `&title=${args.title}`
  }
  if (args.image) {
    shareUrl += `$pic=${args.image}`
  }
  return shareUrl
}

export function weiboShare(args) {
  let shareUrl = 'http://service.weibo.com/share/share.php'
  shareUrl += `?url=${args.url}`
  if (args.title) {
    shareUrl += `&title=${args.title}`
  }
  if (args.source) {
    shareUrl += `$source=${args.source}`
  }
  if (args.appkey) {
    shareUrl += `&appkey=${args.appkey}`
  } else {
    shareUrl += `&appkey=`
  }
  return shareUrl
}

export function doubanShare(args) {
  let shareUrl = 'http://shuo.douban.com/!service/share'
  shareUrl += `?href=${args.url}`
  if (args.title) {
    shareUrl += `&name=${args.title}`
  }
  if (args.description) {
    shareUrl += `$text=${args.description}`
  }
  if (args.image) {
    shareUrl += `$image=${args.image}`
  }
  shareUrl += '&starid=0&aid=0&style=11'
  return shareUrl
}

export function diandianShare(args) {
  let shareUrl = 'http://www.diandian.com/share'
  shareUrl += `?lo=${args.url}`
  if (args.title) {
    shareUrl += `&ti=${args.title}`
  }
  shareUrl += '&type=link'
  return shareUrl
}

export function fbShare(args) {
  let shareUrl = 'https://www.facebook.com/sharer/sharer.php'
  shareUrl += `?u=${args.url}`
  if (args.title) {
    shareUrl += `&title=${args.title}`
  }
  if (args.description) {
    shareUrl += `&description=${args.description}`
  }
  if (args.image) {
    shareUrl += `$picture=${args.image}`
  }
  return shareUrl
}

export function twitterShare(args) {
  let shareUrl = 'https://twitter.com/intent/tweet'
  shareUrl += `?url=${args.url}`

  if (args.title) {
    shareUrl += `&text=${args.title}`
  }
  if (args.via) {
    shareUrl += `&via=${args.via}`
  }
  if (args.tags) {
    shareUrl += `&hashtags=${args.tags}`
  }
  return shareUrl
}

export function linkedInShare(args) {
  let shareUrl = 'http://www.linkedin.com/shareArticle'
  shareUrl += `?url=${args.url}`
  if (args.title) {
    shareUrl += `&title=${args.title}`
  }
  if (args.description) {
    shareUrl += `&summary=${args.description}`
  }
  return shareUrl
}

export function googleShare(args) {
  let shareUrl = 'https://plus.google.com/share'
  shareUrl += `?url=${args.url}`
  return shareUrl
}
