import React, { useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import cookie from "js-cookie";
// reactstrap components
import {
  Button,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import PageHeader from "components/PageHeader";
import Notification from 'rc-notification';
import { getQueryVariable } from 'utils/utils.js'
import './index.scss'
import './notification.less'
import { getListTeachCase, setSubscribe } from 'api/api.js'
import bgImg from 'assets/images/showcase-bg.jpg'
import xmjk from 'assets/images/xmjk.png'
import jmxs from 'assets/images/jmxs.png'
import xzdm from 'assets/images/xzdm.png'
import man from 'assets/images/man.png'
import clock from 'assets/images/clock.png'

let notificationInstance = null
Notification.newInstance({}, n => {
  notificationInstance = n;
});

function CaseListPage() {
  const [headerSolgan, setHeaderSolgan] = useState({title: '下肢动脉案例', solgan: '分享最新研究成果 共建优质科研平台', bgImg})
  const [recommendList, setRecommendList] = useState([])
  const [current, setCurrent] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [hasMore, setHasMore] = useState(true)
  const [email, setEmail] = useState('')
  const history = useHistory()
  const { search } = useLocation()
  const typeList = [
    {title: '下肢动脉案例', type: 1, solgan: '分享最新研究成果 共建优质科研平台'},
    {title: '静脉血栓案例', type: 2, solgan: '分享最新研究成果 共建优质科研平台'},
    {title: '胸主动脉案例', type: 3, solgan: '分享最新研究成果 共建优质科研平台'}
  ]
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    let type = getQueryVariable(history.location.search, 'type')
    typeList.forEach(item => {
      if(item.type == type) {
        let title = {
          title: item.title,
          solgan: item.solgan,
          bgImg
        }
        setHeaderSolgan(title)
      }
    })
    getListTeachCaseList()
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  React.useEffect(() => {
    let type = getQueryVariable(history.location.search, 'type')
    typeList.forEach(item => {
      if(item.type == type) {
        let title = {
          title: item.title,
          solgan: item.solgan,
          bgImg
        }
        setHeaderSolgan(title)
      }
    })
    getListTeachCaseList()
  },[search])

  //获取教育案例列表
  const getListTeachCaseList = async() => {
    let params = {
      caseType: getQueryVariable(history.location.search, 'type')
    }
    let res = await getListTeachCase(params)
    if(res.code == 0) {
      setRecommendList(res.rows)
      if(res.rows.length < 10) {
        setHasMore(false)
      }
    }
  }

  //订阅
  const setSubscribeEmail = async() => {
    const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    if(!email) {
      notificationInstance.notice({
        content: <span>请输入邮箱</span>,
      })
      return
    }
    if(!reg.test(email)) {
      console.log(notificationInstance)
      notificationInstance.notice({
        content: <span>请输入正确的邮箱</span>,
      })
      return
    }
    let params =  {
      websiteType: 1,
      mailbox: email
    }
    let res = await setSubscribe(params)
    if(res.code == 0) {
      notificationInstance.notice({
        content: <span>订阅成功</span>,
      })
    }
  }

  const setSubscribeValue = (e) => {
    setEmail(e.target.value)
  }

  const handleToCaseList = (type) => {
    let currentType = getQueryVariable(history.location.search, 'type')
    console.log(currentType, type)
    if(type == currentType) return
    window.location.href = `${window.location.origin}/#/caselist?type=${type}`
  }

  // 跳转详情
  const handleDetail = (item) => {
    if(item.isMemberExclusive == 1 && !cookie.get('token')) {
      window.location.href = '/#/login'
      return
    }
    history.push(`/videodetail?id=${item.id}&type=1`)
  }

  return (
    <div className="case-list-wapper">
      <Navbar />
      <PageHeader headerSolgan={headerSolgan}></PageHeader>
      <div className="case-list-main auto-height">
        <Container>
          <div className="case-list-lastest">
            <ul className="recommend-list">
              {
                recommendList.map((recommend) => (
                  <li key={recommend.id} onClick={() => handleDetail(recommend)}>
                    <div className="recommend-img">
                      <img src={recommend.thumbnailUrl} alt="" />
                    </div>
                    <div className="recommend-main">
                      <div className="recommend-title">{recommend.title}</div>
                      <div className="recommend-desc">{recommend.description}</div>
                      <div className="recommend-from-date">
                        <div className="recommend-from">
                          <img src={man} alt="" />
                          <span>{recommend.authorInfo} 单位:{recommend.affiliatedUnit}</span>
                        </div>
                        <div className="recommend-date">
                          <img src={clock} alt="" />
                          <span>{recommend.publishTime && recommend.publishTime.split(' ')[0]}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              }
            </ul>
            {
              hasMore?
              <div className="more">
                <div className="line-left"></div>
                <Button color="3d8d9f">查看更多</Button>
                <div className="line-right"></div>
              </div>:null
            }
          </div>
        </Container>
        <div className="classic-case">
          <div className="case-bg"></div>
          <div className="case-list">
            <div className="case-item">经典案例</div>
            <div className="case-item" onClick={() => handleToCaseList(1)}>
              <img src={xmjk} alt="" />
              <span>下肢动脉</span>
            </div>
            <div className="case-item" onClick={() => handleToCaseList(2)}>
              <img src={jmxs} alt="" />
              <span>静脉血栓</span>
            </div>
            <div className="case-item" onClick={() => handleToCaseList(3)}>
              <img src={xzdm} alt="" />
              <span>胸主动脉</span>
            </div>
          </div>
        </div>
        <Container>
          <Row style={{margin: '70px 0'}}>
            <Col md="6">
              <div className="subscribe-tips">
                <h3>获取最新研究资讯？</h3>
                <p>我们每周会将最新研究资讯与研究成果信息通过电子邮件同步给您。</p>
              </div>
            </Col>
            <Col md="6">
              <div className="subscribe-form">
                <img src="" alt="" />
                <Input type="email" onChange={(e) => setSubscribeValue(e)}  placeholder="您的电子邮箱地址" />
                <Button color="3d8d9f" onClick={() => setSubscribeEmail()}>订阅</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <TransparentFooter />
    </div>
  );
}

export default CaseListPage;
