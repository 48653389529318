import React, { useState } from "react";
import { useHistory } from 'react-router-dom'
// reactstrap components
import {
  Button,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import cookie from "js-cookie";

// core components
import Navbar from "components/Navbars/Navbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import PageHeader from "components/PageHeader";
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import Notification from 'rc-notification';
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.scss'
import './index.scss'
import './notification.less'

import { getTopTeachCase, getListTeachCase, setSubscribe } from 'api/api.js'
import bgImg from 'assets/images/education.jpg'
import xmjk from 'assets/images/xmjk.png'
import jmxs from 'assets/images/jmxs.png'
import xzdm from 'assets/images/xzdm.png'
import clock from 'assets/images/clock.png'
import clockF from 'assets/images/clockF.png'
import bIcon from 'assets/images/b.png'

SwiperCore.use([Autoplay, Pagination])

let notificationInstance = null
Notification.newInstance({}, n => {
  notificationInstance = n;
});

function EducationPage() {
  const [swiperList, setSwiperList] = useState([]);
  const [headerSolgan, setHeaderSolgan] = useState({title: '会员专区', solgan: '分享最新研究成果 共建优质科研平台', bgImg, position: '25%'})
  const [lastestList, setLastestList] = useState([])
  const [current, setCurrent] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [hasMore, setHasMore] = useState(true)
  const [email, setEmail] = useState('')
  const [currentSwiper, setCurrentSwiper] = useState({})
  const history = useHistory()
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    function setScale() {
      let scale = window.innerWidth/1920
      let height = window.innerHeight / (window.innerWidth/1920) + 'px'
      document.querySelector('#root').style.zoom = scale //`scale(${scale})`
      document.querySelector('#root .auto-height').style.minHeight = `calc(${height} - 510px)`
    }
    setScale()
    window.onresize = function() {
      setScale()
    }
    getTopTeachCaseList()
    getListTeachCaseList(1)
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const handleToCaseList = (type) => {
    history.push(`/caselist?type=${type}`)
  }

  //获取教育案例置顶列表
  const getTopTeachCaseList = async() => {
    let res = await getTopTeachCase()
    if(res.code == 0) {
      setCurrentSwiper(res.rows[0]?res.rows[0]:{})
      setSwiperList(res.rows)
    }
  }

  //获取教育案例列表
  const getListTeachCaseList = async(current) => {
    let params =  {
      pageSize: pageSize,
      pageNum: current
    }
    let res = await getListTeachCase(params)
    if(res.code == 0) {
      let list = lastestList
      list = list.concat(res.rows)
      setLastestList(list)
      if(res.rows.length < 9) {
        setHasMore(false)
      }
    }
  }

  //订阅
  const setSubscribeEmail = async() => {
    const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    if(!email) {
      notificationInstance.notice({
        content: <span>请输入邮箱</span>,
      })
      return
    }
    if(!reg.test(email)) {
      console.log(notificationInstance)
      notificationInstance.notice({
        content: <span>请输入正确的邮箱</span>,
      })
      return
    }
    let params =  {
      websiteType: 1,
      mailbox: email
    }
    let res = await setSubscribe(params)
    if(res.code == 0) {
      notificationInstance.notice({
        content: <span>订阅成功</span>,
      })
    }
  }

  const setSubscribeValue = (e) => {
    setEmail(e.target.value)
  }

  // 跳转详情
  const handleDetail = (item) => {
    if(item.isMemberExclusive == 1 && !cookie.get('token')) {
      window.location.href = '/#/login'
      return
    }
    if(item.caseKind == 1) {
      history.push(`/videodetail?id=${item.id}&type=1`)
    }else {
      history.push(`/articledetail?id=${item.id}&type=1`)
    }
  }

  // 更多
  const handleMore = () => {
    let currentPage = current + 1
    setCurrent(currentPage)
    getListTeachCaseList(currentPage)
  }

  return (
    <div className="education-wapper">
      <Navbar />
      <PageHeader headerSolgan={headerSolgan}></PageHeader>
      <div className="education-main auto-height">
        <Container>
          <Row className="swiper-wapper">
            <div md="4" style={{padding: 0, width: '380px', height: '450px'}}>
              {
                currentSwiper?
                <div className="forum" onClick={() => handleDetail(currentSwiper)}>
                  <div className="forum-header">
                    <div className="header-title">
                      <img src={bIcon} alt="" />
                      <span>数字医学云讲坛</span>
                    </div>
                    <div className="header-time">
                      <img src={clockF} alt="" />
                      <span>{currentSwiper.duration}min</span>
                    </div>
                  </div>
                  <div className="forum-body">
                    <div className="forum-body-title">{currentSwiper.title}</div>
                    <div className="forum-body-content">{currentSwiper.description}</div>
                    <div className="forum-body-footer">
                      <div className="footer-tips">
                        {
                          currentSwiper.label && currentSwiper.label.split(',').map((label, index) => (
                            <span key={index}>{label}</span>
                          ))
                        }
                      </div>
                      <div className="footer-time">{currentSwiper.publishTime?currentSwiper.publishTime.split(' ')[0]:''}</div>
                    </div>
                  </div>
                </div>:null
              }
            </div>
            <Col md="8" style={{ paddingRight: 0, paddingLeft: 0 }}>
              <Swiper
                style={{ width: '100%' }}
                onSlideChange={(e) => setCurrentSwiper(swiperList[e.activeIndex])}
                onSwiper={(swiper) => console.log(swiper)}
                autoplay={{ delay: 3000, stopOnLastSlide: false, disableOnInteraction: false }}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: false }}
              >
                {
                  swiperList.map(swiper => (
                    <SwiperSlide key={swiper.id}>
                      <div className="swiper-item" onClick={() => handleDetail(swiper)}>
                        {
                          swiper.isMemberExclusive == 1?<span className="tips">会员</span>:null
                        }
                        <img src={swiper.thumbnailUrl} alt="" />
                      </div>
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </Col>
          </Row>
          <div className="education-lastest">
            <div className="lastest-title">最近更新</div>
            <div className="lastest-content">
              {
                lastestList.map((lastest, index) => (
                  <div md="4" style={{margin: `${(index+2)%3==0?'0 30px 40px':'0 0 40px'}`}} key={index} onClick={() => handleDetail(lastest)}>
                    <div style={{width: '100%', position: 'absolute', top: 0,}}>
                      <div className="lastest-img">
                        <img src={lastest.thumbnailUrl} alt="" />
                        {
                          lastest.isMemberExclusive == 1?<span className="tips">会员</span>:null
                        }
                      </div>
                    </div>
                    <div className="lastest-item">
                      <div className="publish-name">
                        <span>{lastest.affiliatedUnit}</span>
                        {
                          lastest.duration?
                          <span className="publish-time">
                            <img src={clock} alt="" />
                            <span>{lastest.duration}min</span>
                          </span>:null
                        }
                      </div>
                      <div className="publish-title">{lastest.title}</div>
                      <div className="publish-tags">
                        {
                          lastest.label && lastest.label.split(',').map((label, index) => (
                            <span key={index}>{label}</span>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
            {
              hasMore?
              <div className="more">
                <div className="line-left"></div>
                <Button color="3d8d9f" onClick={() => handleMore()}>查看更多</Button>
                <div className="line-right"></div>
              </div>:null
            }
          </div>
        </Container>
        <div className="classic-case">
          <div className="case-bg"></div>
          <div className="case-list">
            <div className="case-item">经典案例</div>
            <div className="case-item" onClick={() => handleToCaseList(1)}>
              <img src={xmjk} alt="" />
              <span>下肢动脉</span>
            </div>
            <div className="case-item" onClick={() => handleToCaseList(2)}>
              <img src={jmxs} alt="" />
              <span>静脉血栓</span>
            </div>
            <div className="case-item" onClick={() => handleToCaseList(3)}>
              <img src={xzdm} alt="" />
              <span>胸主动脉</span>
            </div>
          </div>
        </div>
        <Container>
          <Row style={{margin: '70px 0'}}>
            <Col md="6">
              <div className="subscribe-tips">
                <h3>获取最新研究资讯？</h3>
                <p>我们每周会将最新研究资讯与研究成果信息通过电子邮件同步给您。</p>
              </div>
            </Col>
            <Col md="6">
              <div className="subscribe-form">
                <img src="" alt="" />
                <Input type="email" onChange={(e) => setSubscribeValue(e)} placeholder="您的电子邮箱地址" />
                <Button color="3d8d9f" onClick={() => setSubscribeEmail()} >订阅</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <TransparentFooter />
    </div>
  );
}

export default EducationPage;
