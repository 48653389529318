import React, { useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Container,
  Input,
  Row,
  Col
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import PageHeader from 'components/PageHeader/index.js'
import { qqShare, qzoneShare, tencentShare, weiboShare } from 'utils/share.js'
import { getQueryVariable } from 'utils/utils.js'
import { getDetailPaperPeriodical, getShareStat, setShare } from 'api/api.js'
import './index.scss'
import bgImg from 'assets/images/wz.jpg'
import share from 'assets/images/share.png'
import QQ from 'assets/images/QQ.png'
import wechat from 'assets/images/wechat.png'
import weibo from 'assets/images/weibo.png'

function ArticleDetailPage() {
  const [headerSolgan, setHeaderSolgan] = useState({title: '论文/期刊', solgan: '分享最新研究成果 共建优质科研平台', bgImg, position: '25%'})
  const [lastestList, setLastestList] = useState([])
  const [articalDetail, setArticalDetail] = useState({})
  const [shareDatas, setShareDatas] = useState({qqShareNum: 0, wechatShareNum: 0, sinaShareNum: 0})
  const [reverseScale, setReverseScale] = useState(1)
  const history = useHistory()
  const {search} = useLocation()
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    let contentType = getQueryVariable(history.location.search, 'type')
    let solgan = headerSolgan
    if(contentType == 1) {
      solgan.title = '教育案例'
    }else if(contentType == 2) {
      solgan.title = '论文期刊'
    }else if(contentType == 3) {
      solgan.title = '会议活动'
    }
    setHeaderSolgan(solgan)
    function setScale() {
      let scale = window.innerWidth/1920
      let height = 1920/window.innerWidth * 100 + 'vh'
      setReverseScale(1920/window.innerWidth)
      document.querySelector('#root').style.zoom = scale //`scale(${scale})`
      document.querySelector('#root .auto-height').style.minHeight = `calc(${height} - 510px)`
    }
    setScale()
    window.onresize = function() {
      setScale()
    }
    getDetailPaperPeriodicalData(getQueryVariable(history.location.search, 'id'))
    getShareStatData()
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  React.useEffect(() => {
    getDetailPaperPeriodicalData(getQueryVariable(history.location.search, 'id'))
    getShareStatData()
  },[search])

  // 获取文章详情
  const getDetailPaperPeriodicalData = async (id) => {
    let contentType = getQueryVariable(history.location.search, 'type')
    let params = {
      id,
      contentType
    }
    let res = await getDetailPaperPeriodical(params)
    // if(res.code == 0 && res.data) {
      
    // }
    setArticalDetail(res.detail?res.detail:{})
    setLastestList(res.relatedList?res.relatedList:[])
  }

  // 获取分享
  const getShareStatData = async () => {
    let id = getQueryVariable(history.location.search, 'id')
    let params = {
      id,
      contentType: 2
    }
    let res = await getShareStat(params)
    if(res.code == 0 && res.data) {
      setShareDatas(res.data)
    }
  }

  //分享
  const setShareData = async (shareType) => {
    let id = getQueryVariable(history.location.search, 'id')
    let contentType = getQueryVariable(history.location.search, 'type')
    let params = {
      contentId: id,
      contentType,
      shareType
    }
    let res = await setShare(params)
  }

  const getShareUrl = (btn, type) => {
    const args = getShareData(btn)
    let shareUrl = ''
    switch (type) {
      case 'qq':
        setShareData(2)
        let shareDatas1 = shareDatas
        shareDatas1.qqShareNum = shareDatas1.qqShareNum + 1
        setShareDatas(shareDatas1)
        shareUrl = qqShare(args)
        break
      case 'qzone':
        setShareData(1)
        let shareDatas2 = shareDatas
        shareDatas2.wechatShareNum = shareDatas2.wechatShareNum + 1
        setShareDatas(shareDatas2)
        shareUrl = qzoneShare(args)
        break
      case 'tencent':
        shareUrl = tencentShare(args)
        break
      case 'weibo':
        setShareData(3)
        let shareDatas3 = shareDatas
        shareDatas3.sinaShareNum = shareDatas3.sinaShareNum + 1
        setShareDatas(shareDatas3)
        shareUrl = weiboShare(args)
        break
      default:
        return ''
    }
    window.open(shareUrl)
  }

  const getShareData = (data) => {
    const getContent = (selector) => {
      const elem = document.querySelector(selector)
      return elem ? elem.getAttribute('content') : ''
    }
    if (!data) {
      data = {}
    }
    if (!data.url) {
      data.url = ''
    }
    data.url = formatUrl(data.url)
    if (!data.title) {
      data.title = getContent('[name=title], [name=Title]') || document.title
    }
    if (!data.description) {
      data.description = getContent('[name=description], [name=Description]') || document.title
    }
    if (!data.source) {
      data.source = getContent('[name=site], [name=Site]')
    }
    if (!data.image) {
      data.image = document.images[0] ? document.images[0].src : ''
    }
    return data
  }
  const formatUrl = (url) => {
    if (url) {
      const r = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
      if (r.test(url)) {
        return encodeURIComponent(url)
      } else {
        console.warn('[ShareButtons]: Invalid URL')
      }
    }
    return encodeURIComponent(window.location.href)
  }

  const handleLink = (url) => {
    history.push(url)
  }

  return (
    <div className="article-detail-wapper">
      <Navbar />
      <PageHeader headerSolgan={headerSolgan}></PageHeader>
      <div className="article-detail-main auto-height">
        <Container>
          <div className="article-detail-title">
            <div className="title">{articalDetail.title}</div>
            <div className="publish-time">发布于{articalDetail.publishTime}</div>
          </div>
          <div className="article-detail-content" style={{zoom: reverseScale}} dangerouslySetInnerHTML={{__html: articalDetail.content}} ></div>
          <div className="article-detail-info">
            <div className="info-authorInfo">作者：{articalDetail.authorInfo?articalDetail.authorInfo:articalDetail.lecturerInfo}</div>
            <div className="info-affiliatedUnit">单位： {articalDetail.affiliatedUnit}</div>
          </div>
          <div className="article-detail-footer">
            <div className="keyword">
              <span>关键字</span>
              {
                articalDetail.label && articalDetail.label.split(',').map((label, index) => (
                  <span className="keyword-item" key={index}>{label}</span>
                ))
              }
            </div>
            <div className="share">
              <div className="share-icon">
                <img src={share} alt="" />
              </div>
              <div className="share-list">
                <div className="share-item" style={{background: '#3B5998'}} onClick={(e) => getShareUrl(e, 'qzone')}>
                  <img src={wechat} alt="" />
                  <span>{shareDatas.wechatShareNum}</span>
                </div>
                <div className="share-item" style={{background: '#55ACEE'}} onClick={(e) => getShareUrl(e, 'qq')}>
                  <img src={QQ} alt="" />
                  <span>{shareDatas.qqShareNum}</span>
                </div>
                <div className="share-item" style={{background: '#DD4B39'}} onClick={(e) => getShareUrl(e, 'weibo')}>
                  <img src={weibo} alt="" />
                  <span>{shareDatas.sinaShareNum}</span>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="lastest-title">
          <span>相关文章</span>
          <span onClick={() => handleLink('/paperperiodical')} style={{cursor: 'pointer'}}>返回列表</span>
        </div>
        <Row>
        {
          lastestList.map((lastest, index) => (
            <Col md="4" style={{position: 'relative', height: '400px', cursor: 'pointer', marginBottom: '20px'}} key={index} onClick={() => window.location.href = `/#/articledetail?id=${lastest.id}&type=2`} >
              <div className="lastest-img">
                <img src={lastest.thumbnailUrl} alt="" />
                {
                  lastest.isMemberExclusive == 1?<span className="tips">会员</span>:null
                }
              </div>
              <div className="lastest-item">
                <div className="publish-name">
                  <span>{lastest.title}</span>
                  <span className="publish-time">
                    {/* <img src="" alt="" />
                    <span>52min</span> */}
                  </span>
                </div>
                <div className="publish-title">{lastest.description}</div>
                <div className="publish-tags">
                  {/* {
                    lastest.label && lastest.label.split(',').map((label, index) => (
                      <span key={index}>{label}</span>
                    ))
                  } */}
                </div>
              </div>
            </Col>
          ))
        }
      </Row>
      </Container>
      <TransparentFooter />
    </div>
  );
}

export default ArticleDetailPage;
