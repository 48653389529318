import React, { useState } from "react";
import { useHistory } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import PageHeader from "components/PageHeader";
import './index.scss'
import searchIcon from 'assets/img/paper/search-fff.png'
import { getListPaperPeriodical } from 'api/api.js'
import { getQueryVariable } from 'utils/utils.js'
import bgImg from 'assets/images/wz.jpg'
import man from 'assets/images/man.png'
import clock from 'assets/images/clock.png'

function PaperAndPeriodicalPage() {
  const [headerSolgan, setHeaderSolgan] = useState({title: '论文/期刊', solgan: '分享最新研究成果 共建优质科研平台', bgImg, position: '25%'})
  const [searchBar, setSearchBar] = useState([{name: '全部', id: 0},{name: '血液专病', id: 1},{name: '血管健康', id: 2},{name: '栓塞专题', id: 3},{name: '术后护理', id: 4}])
  const [lastestList, setLastestList] = useState([])
  const [current, setCurrent] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [currentLabel, setCurrentLabel] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [hasMore, setHasMore] = useState(true)
  const history = useHistory()
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    let search = decodeURIComponent(getQueryVariable(history.location.search, 'search'))
    setSearchValue(search)
    getPaperPeriodicalList(1, search, 0)
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const handleChangeLabel = (label) => {
    setCurrentLabel(label.id)
    setCurrent(1)
    setLastestList([])
    getPaperPeriodicalList(1, searchValue, label.id)
  }

  // 最近更新列表
  const getPaperPeriodicalList = async (current, searchValue, currentLabel) => {
    let params =  {
      pageSize: pageSize,
      pageNum: current,
      title: searchValue
    }
    if(currentLabel != 0) {
      params.periodicalType = currentLabel
    }
    let res = await getListPaperPeriodical(params)
    if(res.code == 0) {
      let list = lastestList
      if(current == 1) {
        list = []
      }
      list = list.concat(res.rows)
      setLastestList(list)
      if(res.rows.length < 10) {
        setHasMore(false)
      }else {
        setHasMore(true)
      }
    }
  }

  // 活动详情
  const handleDetail = (item) => {
    history.push(`/articledetail?id=${item.id}&type=2`)
  }

  //更多
  const handleMore = () => {
    let currentPage = current + 1
    setCurrent(currentPage)
    getPaperPeriodicalList(currentPage, searchValue, currentLabel)
  }

  // 搜索
  const handleSearch = () => {
    getPaperPeriodicalList(1, searchValue, currentLabel)
  }

  return (
    <div className="search-list-wapper">
      <Navbar />
      <PageHeader headerSolgan={headerSolgan}></PageHeader>
      <div className="search-list-main">
        <Container>
          <div className="search-list-lastest">
            <div className="search-list-search-bar">
              <ul className="search-bar-labels">
                {
                  searchBar.map(search => (
                    <li key={search.id} onClick={() => handleChangeLabel(search)} className={search.id == currentLabel?'active':''}>{search.name}</li>
                  ))
                }
              </ul>
              <div className="search-bar">
                <Input type="text" defaultValue={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="请输入查询关键字" />
                <div className="search-btn" onClick={() => handleSearch()}>
                  <img src={searchIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="lastest-header">
              <div className="lastest-title">搜索结果</div>
            </div>
            <ul className="lastest-list">
              {
                lastestList.map((lastest, index) => (
                  <li key={index} onClick={() => handleDetail(lastest)}>
                    <div className="recommend-main">
                      <div className="recommend-title">{lastest.title}</div>
                      <div className="recommend-from-date">
                        <div className="recommend-from">
                          {
                            lastest.label && lastest.label.split(',').map((label, index) => (
                              <span className="lastest-tag" key={index}>{label}</span>
                            ))
                          }
                        </div>
                        <div className="recommend-date">
                          <img src={clock} alt="" />
                          <span>{lastest.publishTime && lastest.publishTime.split(' ')[0]}</span>
                        </div>
                      </div>
                      <div className="recommend-desc">{lastest.description}</div>
                      <div className="recommend-from-date">
                        <div className="recommend-from">
                          <img src={man} alt="" />
                          <span>{lastest.authorInfo} 单位:{lastest.affiliatedUnit}</span>
                        </div>
                        <div className="recommend-date">
                          <span>临床试验进展</span>
                          <span className="publish-status">[{lastest.publishStatus == 1?'已发表':'未发表'}]</span>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              }
            </ul>
            {
              hasMore?
              <div className="more">
                <div className="line-left"></div>
                <Button color="3d8d9f" onClick={() => handleMore()}>查看更多</Button>
                <div className="line-right"></div>
              </div>:null
            }
          </div>
        </Container>
      </div>
      <TransparentFooter />
    </div>
  );
}

export default PaperAndPeriodicalPage;
