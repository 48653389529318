import React, {useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import { login } from 'api/api.js'
import './index.scss'
import cookie from "js-cookie";

function LoginPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [loginName, setLoginName] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [height, setHeight] = useState(0)
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    window.onresize = function() {
      setHeiht()
      setScales()
    }
    document.body.scrollTop = 0;
    function setScales() {
      let scale = window.innerWidth/1920
      document.querySelector('#root').style.zoom = scale //`scale(${scale})`
    }
    setScales()
    setHeiht()
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  //登录
  const handleLogin = async (e) => {
    e.preventDefault()
    console.log(loginName, password)
    let params =  {
      loginName,
      password
    }
    let res = await login(params)
    if(res.token) {
      cookie.set('token', res.token)
      localStorage.setItem('userInfo', JSON.stringify(res.userInfo))
      window.location.href = '/#/platform'
    }
  }

  const setHeiht = () => {
    let scale = window.innerHeight / (window.innerWidth/1920) + 'px'
    setHeight(scale)
  }

  return (
    <>
      <Navbar />
      <div className="page-header clear-filter" style={{minHeight: height, maxHeight: 'inherit'}} filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/login.jpg").default + ")",
          }}
        ></div>
        <div className="content login-content" style={{marginTop: 0,
          display: 'flex',
          height: '100%',
          alignItems: 'center',}}>
          <Container>
            <Col className="ml-auto mr-auto" md="4" style={{transform: 'scale(1.5)'}}>
              <Card className="card-login card-plain">
                <Form action="" className="form" method="">
                  <CardHeader className="text-center">
                    <div className="logo-container">
                      <img
                        alt="..."
                        src={require("assets/images/vascbase1.png").default}
                      ></img>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={
                        "no-border" +
                        (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="用户名"
                        type="text"
                        onChange={(e) => setLoginName(e.target.value)}
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border" +
                        (lastFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_lock-circle-open"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="密码"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        onFocus={() => setLastFocus(true)}
                        onBlur={() => setLastFocus(false)}
                      ></Input>
                    </InputGroup>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      block
                      className="btn-round"
                      color="info"
                      onClick={(e) => handleLogin(e)}
                    >
                      开 始 登 录
                    </Button>
                    <div className="pull-left">
                      <h6>
                        <a
                          className="link"
                          href="/#/contactus"
                        >
                          加入我们
                        </a>
                      </h6>
                    </div>
                    <div className="pull-right">
                      <h6>
                        <a
                          className="link"
                          href=""
                          onClick={(e) => e.preventDefault()}
                        >
                          忘记密码
                        </a>
                      </h6>
                    </div>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default LoginPage;
