import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Container,
  Input,
  Row,
  Col
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import PageHeader from 'components/PageHeader/index.js'
import { qqShare, qzoneShare, tencentShare, weiboShare } from 'utils/share.js'
import { getQueryVariable, countDown } from 'utils/utils.js'
import { getDetailTeachCase, getShareStat, setShare } from 'api/api.js'
import ReactPlayer from "react-player";
import './index.scss'
import bgImg from 'assets/images/video.jpg'
import share from 'assets/images/share.png'
import QQ from 'assets/images/QQ.png'
import wechat from 'assets/images/wechat.png'
import weibo from 'assets/images/weibo.png'

function VideoDetailPage() {
  const [headerSolgan, setHeaderSolgan] = useState({title: '活动详情', solgan: '分享最新研究成果 共建优质科研平台', bgImg, color: '#333'})
  const [videoList, setVideoList] = useState([])
  const [currentVideo, setCurrentVideo] = useState({})
  const [shareDatas, setShareDatas] = useState({qqShareNum: 0, wechatShareNum: 0, sinaShareNum: 0})
  const [liveStartStatus, setLiveStartStatus] = useState(false)
  const [muted, setMuted] = useState(true)
  const history = useHistory()
  const { search } = useLocation()
  
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    let contentType = getQueryVariable(history.location.search, 'type')
    let solgan = headerSolgan
    if(contentType == 1) {
      solgan.title = '教育案例'
    }else if(contentType == 2) {
      solgan.title = '论文期刊'
    }else if(contentType == 3) {
      solgan.title = '会议活动'
    }
    setHeaderSolgan(solgan)
    window.timer = null
    clearInterval(window.timer)
    function setScale() {
      let scale = window.innerWidth/1920
      let height = 1920/window.innerWidth * 100 + 'vh'
      document.querySelector('#root').style.zoom = scale //`scale(${scale})`
      document.querySelector('#root .auto-height').style.minHeight = `calc(${height} - 510px)`
    }
    setScale()
    window.onresize = function() {
      setScale()
    }
    // getVideoDetail()
    // getShareStatData()
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  useEffect(() => {
    return () => clearInterval(window.timer)
  },[])

  React.useEffect(() => {
    window.timer = null
    clearInterval(window.timer)
    setCurrentVideo({})
    getVideoDetail()
    getShareStatData()
    return () => clearInterval(window.timer)
  }, [search])


  // 获取分享
  const getShareStatData = async () => {
    let id = getQueryVariable(history.location.search, 'id')
    let contentType = getQueryVariable(history.location.search, 'type')
    let params = {
      id,
      contentType
    }
    let res = await getShareStat(params)
    if(res.code == 0 && res.data) {
      setShareDatas(res.data)
    }
  }

  //分享
  const setShareData = async (shareType) => {
    let id = getQueryVariable(history.location.search, 'id')
    let contentType = getQueryVariable(history.location.search, 'type')
    let params = {
      contentId: id,
      contentType,
      shareType
    }
    let res = await setShare(params)
  }

  // 获取详情
  const getVideoDetail = async () => {
    let id = getQueryVariable(history.location.search, 'id')
    let contentType = getQueryVariable(history.location.search, 'type')
    let params = {
      id,
      contentType
    }
    let res = await getDetailTeachCase(params)
    // if(res.code == 0) {
      setCurrentVideo(res.detail?res.detail:{})
      setTimeout(() => {
        if(res.detail && res.detail.liveStatus == 1 && res.detail.startTime && new Date(res.detail.startTime).getTime() < new Date().getTime()) {
          setLiveStartStatus(true)
        }else {
          setLiveStartStatus(false)
          window.timer = setInterval(() => {
            if(currentVideo.startTime && currentVideo.liveStatus == 1 && new Date(currentVideo.startTime).getTime() < new Date().getTime()) {
              setLiveStartStatus(true)
              clearInterval(window.timer)
            }else {
              setLiveStartStatus(false)
            }
          },2000)
        }
      },100)
      setVideoList(res.relatedList)
    // }
  }

  // 跳转
  const handleDetail = (item) => {
    let contentType = getQueryVariable(history.location.search, 'type')
    window.location.href = `/#/videodetail?id=${item.id}&type=${contentType}`
  }

  const getShareUrl = (btn, type) => {
    const args = getShareData(btn)
    let shareUrl = ''
    switch (type) {
      case 'qq':
        setShareData(2)
        let shareDatas1 = shareDatas
        shareDatas1.qqShareNum = shareDatas1.qqShareNum + 1
        setShareDatas(shareDatas1)
        shareUrl = qqShare(args)
        break
      case 'qzone':
        setShareData(1)
        let shareDatas2 = shareDatas
        shareDatas2.wechatShareNum = shareDatas2.wechatShareNum + 1
        setShareDatas(shareDatas2)
        shareUrl = qzoneShare(args)
        break
      case 'tencent':
        shareUrl = tencentShare(args)
        break
      case 'weibo':
        setShareData(3)
        let shareDatas3 = shareDatas
        shareDatas3.sinaShareNum = shareDatas3.sinaShareNum + 1
        setShareDatas(shareDatas3)
        shareUrl = weiboShare(args)
        break
      default:
        return ''
    }
    window.open(shareUrl)
  }

  const getShareData = (data) => {
    const getContent = (selector) => {
      const elem = document.querySelector(selector)
      return elem ? elem.getAttribute('content') : ''
    }
    if (!data) {
      data = {}
    }
    if (!data.url) {
      data.url = ''
    }
    data.url = formatUrl(data.url)
    if (!data.title) {
      data.title = getContent('[name=title], [name=Title]') || document.title
    }
    if (!data.description) {
      data.description = getContent('[name=description], [name=Description]') || document.title
    }
    if (!data.source) {
      data.source = getContent('[name=site], [name=Site]')
    }
    if (!data.image) {
      data.image = document.images[0] ? document.images[0].src : ''
    }
    return data
  }
  const formatUrl = (url) => {
    if (url) {
      const r = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
      if (r.test(url)) {
        return encodeURIComponent(url)
      } else {
        console.warn('[ShareButtons]: Invalid URL')
      }
    }
    return encodeURIComponent(window.location.href)
  }

  return (
    <div className="video-detail-wapper">
      <Navbar color={'#333'} />
      <PageHeader headerSolgan={headerSolgan}></PageHeader>
      <div className="video-detail-main auto-height">
        <Container>
          <div className="video-detail-content">
            <div className="video-content">
              <div className="video-wapper">
                {
                  currentVideo.liveStatus != 1?
                  <ReactPlayer
                    url={currentVideo.videoUrl}
                    controls
                    width={780}
                    height={430}
                    poster={currentVideo.thumbnailUrl}
                  >
                  </ReactPlayer>:
                  <>
                    {
                      liveStartStatus?
                      <ReactPlayer 
                        url={currentVideo.liveUrl}
                        controls
                        width={780}
                        height={430}
                        playing={true}
                        muted={muted}
                        config={{
                          file: {
                            forceHLS: true
                          }
                        }}
                      ></ReactPlayer>:<img src={currentVideo.thumbnailUrl} alt="" />
                    }
                  </>
                }
              </div>
              <div className="video-desc">
                <div className="video-title">
                  <span title={currentVideo.title}>{currentVideo.title}</span>
                  <span title={currentVideo.source}>{currentVideo.source}</span>
                </div>
                <div className="video-info">{currentVideo.description}</div>
                <div className="video-detail-footer">
                  <div className="keyword">
                    {
                      currentVideo.label && currentVideo.label.split(',').map((label, index) => (
                        <span className="keyword-item" key={index}>{label}</span>
                      ))
                    }
                  </div>
                  <div className="share">
                    <div className="share-icon">
                      <img src={share} alt="" />
                    </div>
                    <div className="share-list">
                      <div className="share-item" style={{background: '#3B5998'}} onClick={(e) => getShareUrl(e, 'qzone')}>
                        <img src={wechat} alt="" />
                        <span>{shareDatas.wechatShareNum}</span>
                      </div>
                      <div className="share-item" style={{background: '#55ACEE'}} onClick={(e) => getShareUrl(e, 'qq')}>
                        <img src={QQ} alt="" />
                        <span>{shareDatas.qqShareNum}</span>
                      </div>
                      <div className="share-item" style={{background: '#DD4B39'}} onClick={(e) => getShareUrl(e, 'weibo')}>
                        <img src={weibo} alt="" />
                        <span>{shareDatas.sinaShareNum}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="video-list">
              {
                videoList.map((item, index) => (
                  <div className="video-item" key={index} onClick={() => handleDetail(item)}>
                    <img src={item.thumbnailUrl} alt="" />
                    {
                      item.startTime?
                      <div className="video-status">{`距离开始：${countDown(item.startTime)}`}</div>:null
                    }
                  </div>
                ))
              }
            </div>
          </div>
        </Container>
      </div>
      <TransparentFooter />
    </div>
  );
}

export default VideoDetailPage;
