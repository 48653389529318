import React, { Fragment, useState } from "react";
import cookie from "js-cookie";
import axios from "axios";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Col,
  Row,
} from "reactstrap";
import Rate from "rc-rate";

// core components
import Navbar from "components/Navbars/Navbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import { getDataPlatform, loginSys } from 'api/api.js'
import './index.scss'
import 'rc-rate/assets/index.css'

import xmjk from 'assets/images/hxmjk.png'
import jmxs from 'assets/images/hjmxs.png'
import xzdm from 'assets/images/hxzdm.png'

function DataPlatformPage() {
  const [data, setData] = useState({userSysList: [], contribution: 0, projectNum: 0, totalCaseNum: 0, totalPatientNum: 0})
  const [height, setHeight] = useState(0)
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    setHeiht()
    window.onresize = function() {
      setHeiht()
      setScales()
    }
    function setScales() {
      let scale = window.innerWidth/1920
      document.querySelector('#root').style.zoom = scale //`scale(${scale})`
    }
    setScales()
    let token = cookie.get('token')
    if(!token) {
      window.location.href = '/#/login'
    }
    getDataList()
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const setHeiht = () => {
    let scale = window.innerHeight / (window.innerWidth/1920) + 'px'
    setHeight(scale)
  }

  // 获取数据列表
  const getDataList = async () => {
    let res = await getDataPlatform()
    console.log(res)
    if(res.code == 0) {
      res.data.contribution = parseInt(res.data.contribution) / 20
      setData(res.data)
    }else if(res.code == 401) {
      cookie.remove('token')
      localStorage.removeItem('userInfo')
      window.location.href = `${window.location.origin}/#/login`;
    }
  }

  const onChange = (e) => {
    console.log(e)
  }

  const handleLogin = async (user) => {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    let params = {
      systemId: user.systemId
    }
    let res = await loginSys(params)
    if(res.code == 0) {
      window.open(`${user.url}/api/index?username=${userInfo.loginName}&sessionId=${res.data.sessionId}`)
    }
  }

  return (
    <Fragment>
      <Navbar />
      <div className="platform-header clear-filter" style={{minHeight: height, maxHeight: 'initial'}} filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/login.jpg").default + ")",
          }}
        ></div>
        <div className="content">
          <Container>
            <Row>
              <Col md="6">
                <div className="platform-info" style={{marginTop: 0,}}>
                  <div className="info-name">
                    <div><span></span></div>
                  </div>
                  <div>
                    <span>病例数</span>
                  </div>
                  <div>
                    <span>患者总数</span>
                  </div>
                </div>
                {
                  data.userSysList.map((userSys) => (
                    <div className="platform-info">
                      <div className="info-name">
                        <div><span>{userSys.systemName}</span></div>
                      </div>
                      <div>
                        <div><span>{userSys.sysTotalCaseNum}</span>人次</div>
                      </div>
                      <div>
                        <div><span>{userSys.sysTotalPatientNum}</span>人</div>
                      </div>
                    </div>
                  ))
                }
                <div className="platform-info">
                  <div className="info-name">
                    <div><span>平台累计</span></div>
                  </div>
                  <div>
                    <div><span>{data.platToalCaseNum}</span>人次</div>
                  </div>
                  <div>
                    <div><span>{data.platTotalPatientNum}</span>人</div>
                  </div>
                </div>
              </Col>
              <Col md="1"></Col>
              <Col md="5">
                <Card className="platform-card">
                  <CardHeader className="platform-title">
                    <h3>电子病案分析平台</h3>
                    <p>——智能化临床研究服务云平台</p>
                  </CardHeader>
                  <CardBody>
                    <ul className="platform-list">
                      {
                        data.userSysList.map(user=> (
                          <li onClick={() => handleLogin(user)} key={user.id}>
                            <img src={user.systemId==1?xmjk:user.systemId==2?jmxs:xzdm} alt="" />
                            <span>{user.systemName}</span>
                          </li>
                        ))
                      }
                    </ul>
                  </CardBody>
                  <CardFooter className="text-center">
                    <div>
                      <Rate
                        value={data.contribution}
                        onChange={onChange}
                        style={{ fontSize: 20 }}
                        allowHalf
                        disabled
                        allowClear={false}
                      />
                      <span>平台贡献度</span>
                    </div>
                    <div>
                      <div><span>{data.projectNum}</span>个</div>
                      <span>研究项目</span>
                    </div>
                    <div>
                      <div><span>{data.totalCaseNum}</span>人次</div>
                      <span>病例数</span>
                    </div>
                    <div>
                      <div><span>{data.totalPatientNum}</span>人</div>
                      <span>患者总数</span>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div style={{ position: 'absolute', width: '100%', bottom: 0}}>
          <TransparentFooter />
        </div>
      </div>
    </Fragment>
  );
}

export default DataPlatformPage;
