import axios from 'axios'
import cookie from 'js-cookie'
// import { message, notification } from 'antd';


// 创建 axios 实例
const service = axios.create({
  withCredentials: false,
  baseURL: '', // api base_url
  timeout: 60000, // 请求超时时间
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
})

const err = (error) => {
  
  if (error.response) {
    console.log(error.response)
    const data = error.response.data
    if (error.response.status === 403) {
      // notification.error({
      //   message: 'Forbidden',
      //   description: data.message
      // })
    }
    if (error.response.status === 401) {
      // notification.error({
      //   message: 'Unauthorized',
      //   description: '登录过期，请重新登录'
      // })
      const token = cookie.get('token');
      if (token) {
        cookie.remove('token')
        window.location.href = `${window.location.origin}/#/login`;
      }else{
        localStorage.removeItem('userInfo');
        window.location.href = `${window.location.origin}/#/login`;
      }
    }
    if(error.response.status === 500) {
      // notification.error({
      //   message: '服务器端错误',
      //   description: '服务器端错误,请稍后重试'
      // })
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const token = cookie.get('token');
  if (token) {
    config.headers['Token'] = `${token}` // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  if(response.headers.authtoken) {
    cookie.set('token', response.headers.authtoken, { expires: 7 });
  }
  return response.data
}, err)

export default service;