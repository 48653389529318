import React, { useState } from "react";
import { useHistory } from  'react-router-dom'

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import PageHeader from "components/PageHeader";
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.scss'
import './index.scss'

import { getDynamicData, getDynamicSwper } from '../../../api/api'
import bgImg from 'assets/img/bg0.jpg'

SwiperCore.use([Autoplay, Pagination])

function DynamicPage() {
  const [swiperList, setSwiperList] = useState([]);
  const [headerSolgan, setHeaderSolgan] = useState({title: '', solgan: '', bgImg}) //分享最新研究成果 共建优质科研平台 联盟动态
  const [observationList, setObservationList] = useState([])
  const [newsList, setNewsList] = useState([])
  const history = useHistory()
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    function setScale() {
      let scale = window.innerWidth/1920
      let height = window.innerHeight / (window.innerWidth/1920) + 'px'
      document.querySelector('#root').style.zoom = scale //`scale(${scale})`
      document.querySelector('#root .auto-height').style.minHeight = `calc(${height} - 510px)`
    }
    setScale()
    window.onresize = function() {
      setScale()
      setScales()
    }
    function setScales() {
      let scale = window.innerWidth/1920
      document.querySelector('#root').style.zoom = scale //`scale(${scale})`
    }
    setScales()
    getDynamicList()
    getObservationList()
    getDynamicSwperList()
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  //获取联盟动态
  const getDynamicList = async () => {
    let params =  {
      pageSize: 10,
      pageNum: 2
    }
    let res = await getDynamicData(params)
    if(res.code == 0) {
      if(res.total > 10) {
        setNewsList(res.rows)
      }
    }
  }

  //获取联盟观察
  const getObservationList = async () => {
    let params =  {
      pageSize: 10,
      pageNum: 1
    }
    let res = await getDynamicData(params)
    if(res.code == 0) {
      setObservationList(res.rows)
    }
  }

  // 获取轮播图
  const getDynamicSwperList = async () => {
    let res = await getDynamicSwper()
    if(res.code == 0) {
      res.rows.map(swiper => {
        swiper.caseKind = 1
      })
      setSwiperList(res.rows)
    }
  }

  //跳转详情
  const handleToMore = (item) => {
    console.log(item.contentType)
    let url = ''
    if(item.contentType == 1) {
      if(item.caseKind == 1) {
        url = `/videoDetail?id=${item.contentId?item.contentId:item.id}&type=1`
      }else {
        url = `/articledetail?id=${item.contentId}&type=1`
      }
    }else if(item.contentType == 2) {
      url = `/articledetail?id=${item.contentId}&type=2`
    }else if(item.contentType == 3) {
      if(item.activeKind == 1) {
        url = `/videoDetail?id=${item.contentId?item.contentId:item.id}&type=3`
      }else {
        url = `/activitydetail?id=${item.contentId}&type=3`
      }
    }
    history.push(url)
  }

  return (
    <div className="dynamic-wapper">
      <Navbar color={'#333'}/>
      <PageHeader headerSolgan={headerSolgan}></PageHeader>
      <div className="dynamic-main auto-height">
        <Container>
          <div className="swiper-wapper">
            <Swiper
              style={{ width: '100%' }}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
              autoplay={{ delay: 3000, stopOnLastSlide: false, disableOnInteraction: false }}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: false }}
            >
              {
                swiperList.map(swiper => (
                  <SwiperSlide key={swiper.id}>
                    <div className="swiper-item" style={{background: swiper.bgColor}} onClick={() => handleToMore(swiper)}>
                      {
                        swiper.liveStatus == 1?<span className="tips">正在直播</span>:null
                      }
                      <img src={swiper.picUrl} alt="" />
                    </div>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
          <Row style={{marginBottom: '40px'}}>
            <Col md="6">
              {
                /**
                 * <div className="dynamic-content-title" onClick={() => history.push(`/dynamicmore?type=1`)}>
                <div className="title-text">
                  <span>联盟观察</span>
                  <span>Observation</span>
                  <span className="line"></span>
                </div>
                <div className="title-next">{">"}</div>
              </div>
                 */
              }
              <ul className="dynamic-content-list">
                {
                  observationList.map(observation => (
                    <li className="dynamic-item" key={observation.id} style={{ paddingRight: '20px', marginTop: '20px'}} onClick={() => handleToMore(observation)}>
                      <div className="dynamic-item-desc">
                        <span className="spot"></span>
                        <span className="label">
                          {
                            observation.contentType == 1?'教育案例':observation.contentType == 2?'论文期刊':observation.contentType == 3?'会议活动':''
                          }
                        </span>
                        <p>{observation.title}</p>
                      </div>
                      <div className="dynamic-item-time">{observation.publishTime?observation.publishTime.split(' ')[0]:''}</div>
                    </li>
                  ))
                }
              </ul>
            </Col>
            <Col md="6">
              {
                /**
                 * <div className="dynamic-content-title" onClick={() => history.push(`/dynamicmore?type=2`)}>
                <div className="title-text">
                  <span>联盟动态</span>
                  <span>News</span>
                  <span className="line"></span>
                </div>
                <div className="title-next">{">"}</div>
              </div>
                 */
              }
              <ul className="dynamic-content-list">
                {
                  newsList.map(news => (
                    <li className="dynamic-item" key={news.id} style={{ paddingRight: '20px', marginTop: '20px'}} onClick={() => handleToMore(news)} >
                      <div className="dynamic-item-desc">
                        <span className="spot"></span>
                        <span className="label">
                          {
                            news.contentType == 1?'教育案例':news.contentType == 2?'论文期刊':news.contentType == 3?'会议活动':''
                          }
                        </span>
                        <p>{news.title}</p>
                      </div>
                      <div className="dynamic-item-time">{news.publishTime?news.publishTime.split(' ')[0]:''}</div>
                    </li>
                  ))
                }
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <TransparentFooter />
    </div>
  );
}

export default DynamicPage;
